import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { Observable } from 'rxjs'

@Injectable()
export class AuthenticityInterceptor implements HttpInterceptor {
  constructor(private meta: Meta) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const paramElem = this.meta.getTag('name="csrf-param"')
    const tokenElem = this.meta.getTag('name="csrf-token"')

    if (paramElem && tokenElem) {
      req = req.clone({
        setHeaders: { [paramElem.content]: tokenElem.content }
      })
    }

    return next.handle(req)
  }
}
