import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { environment } from '../environments/environment'
import { CacheService, UserSessionService } from './core/services'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  WindowResizeService,
  ConsoleLogger,
  GlobalErrorHandler,
  TitleService
} from './shared/services'

declare var dataLayer
declare var gtag: (...args) => void
@UntilDestroy()
@Component({
  selector: 'mpv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  constructor(
    @Inject('WINDOW') win: Window,
    public session: UserSessionService,
    errorHandler: GlobalErrorHandler,
    cache: CacheService,
    consoleLogger: ConsoleLogger,
    title: TitleService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private windowResize: WindowResizeService
  ) {
    errorHandler.logToHoneybadger = environment.logToHoneybadger
    consoleLogger.logLevel = environment.logLevel
    consoleLogger.logPrefix = '[C1]'
    title.appName = 'Clear1'

    cache.createCache('chartData', {
      matchers: [
        /\/api\/v\d\/total_asset_value\/from\/\d{4}\/\d{1,2}.json/i,
        /\/api\/v\d\/asset_change_detail\/from\/\d{4}\/\d{1,2}.json/i,
        /\/api\/v\d\/contribution_withdrawal_dates\/from\/\d{4}\/\d{1,2}.json/i,
        /\/api\/v\d\/account\/[^/]+\/total_asset_value\/from\/\d{4}\/\d{1,2}.json/i,
        /\/api\/v\d\/account\/[^/]+\/asset_change_detail\/from\/\d{4}\/\d{1,2}.json/i,
        /\/api\/v\d\/account\/[^/]+\/contribution_withdrawal_dates\/from\/\d{4}\/\d{1,2}.json/i
      ],
      limit: 50,
      deepCopy: true,
      alwaysRefresh: true
    })

    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      win.scrollTo(0, 0)
    })

    this.windowResize.setSize(window.innerWidth)
  }

  ngOnDestroy(): void {}

  public ngOnInit(): void {
    this.addGoogleAnalitycs()
    this.runLoginTasks()
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.changeDetector.detectChanges()
    })
  }

  ngAfterViewChecked(): void {}

  private addGoogleAnalitycs() {
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.analitycsId
    script.async = true
    document.body.appendChild(script)

    window['dataLayer'] = window['dataLayer'] || []
    window['gtag'] = function() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', environment.analitycsId)
  }

  private runLoginTasks() {
    let workgroupCode = ''
    let realmCode = ''
    const groups =
      navigator && navigator.userAgent ? navigator.userAgent.match(/\(([^)]+)\)/gi) : null
    const device = groups && groups[0] ? groups[0].replace(/[()]/gi, '') : ''

    this.session.currentSession$.subscribe(session => {
      if (session) {
        workgroupCode = session.workgroup_code
        realmCode = session.realm_code

        gtag('config', environment.analitycsId, {
          user_id: session.member_id,
          send_page_view: false,
          clear1_workgroup_userscope: workgroupCode,
          clear1_brokerdealer_userscope: realmCode,
          platform: window.navigator.platform,
          user_agent: window.navigator.userAgent,
          device
        })

        gtag('set', 'user_properties', {
          user_id: session.member_id,
          clear1_workgroup_userscope: workgroupCode,
          clear1_brokerdealer_userscope: realmCode,
          platform: window.navigator.platform,
          user_agent: window.navigator.userAgent,
          device
        })
      }
    })

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event: NavigationEnd) => {
        const pagePath = event.url
        const title = pagePath.replace(/[\/;=]/gi, ' ').trim()

        gtag('event', 'page_view', {
          page_title: title,
          page_location: event.url,
          page_path: pagePath,
          send_to: environment.analitycsId,
          clear1_workgroup_eventscope: workgroupCode,
          clear1_brokerdealer_eventscope: realmCode,
          platform: window.navigator.platform,
          user_agent: window.navigator.userAgent,
          device
        })
      })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowResize.setSize(event.target.innerWidth)
  }
}
