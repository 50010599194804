<footer class="footerDisclaimer">
  <div class="disclaimer px-8 py-10 mb-4 md:mb-0 md:pb-0 md:mt-10">
    <div class="mb-4 md:hidden print-hidden">
      <a
        (click)="displayDisclaimer = !displayDisclaimer"
        class="flex items-center justify-center btnicon"
      >
        <span class="text-base mr-2" *ngIf="displayDisclaimer">Hide disclaimer</span>
        <span class="text-base mr-2" *ngIf="!displayDisclaimer">Show disclaimer</span>
        <mpv-icon
          *ngIf="!displayDisclaimer"
          icon="chevron-down"
          height="9px"
          [alt]="'Hide disclaimer'"
          roletype="button"
        ></mpv-icon>
        <mpv-icon
          *ngIf="displayDisclaimer"
          icon="chevron-up"
          height="9px"
          [alt]="'Show disclaimer'"
          roletype="button"
        ></mpv-icon>
      </a>
    </div>
    <div
      class="md:block print-block"
      [class.hidden]="!displayDisclaimer"
      [class.block]="displayDisclaimer"
    >
      <div [innerHTML]="documentsDisclaimer" class="mb-4"></div>
      <div [innerHTML]="disclaimer" class="mb-4" (click)="validateLinks($event)"></div>
      <p class="mb-4">
        Information appearing on Clear 1 has been prepared for informational purposes, and market
        prices shown should only be used as a general guide to market value, which may vary from
        actual liquidation value. This display of information has been prepared from data believed
        to be reliable, but no representation is being made as to its accuracy or completeness, and
        in addition not all product providers provide account data. Since the cost basis on certain
        securities may have been provided by another source, the cost basis information in Clear 1
        may not reflect accurate data or correspond to data on your trade confirmations. This
        information should not be relied upon for tax reporting purposes nor does it replace the
        statements you should receive directly from Pershing and/or product providers, as
        applicable. We believe the sources to be reliable, however the accuracy and completeness of
        the information is not guaranteed. In the event of a discrepancy, the sponsor's valuation
        shall prevail. The source data for accounts may be provided by the following third party
        providers: DST FAN Mail, Depository Trust &amp; Clearing Corporation (DTCC), DAZL, Pershing
        or other data providers.
      </p>

      <div [ngSwitch]="brokerDealerCode" class="mb-4">
        <p *ngSwitchCase="'NAVY'">
          For additional information, please visit our website:
          <a
            href="https://www.navyfederal.org/financial-group/disclosures.php"
            target="_blank"
            rel="noopener"
          >
            www.navyfederal.org/financial-group/disclosures.php</a
          >.
        </p>

        <p *ngSwitchCase="'WFS'">
          For additional information, please visit our website:
          <a href="https://www.wescomfinancial.com/" target="_blank" rel="noopener">
            www.wescomfinancial.com</a
          >.
        </p>

        <p *ngSwitchCase="'CG'">
          For additional information, please visit our website:
          <a href="https://www.cadaretgrant.com/disclosures" target="_blank" rel="noopener">
            www.cadaretgrant.com/disclosures</a
          >.
        </p>

        <p *ngSwitchCase="'NEXT'">
          For additional information, please visit our website:
          <a
            href="https://www.nextfinancial.com/customers/disclosures"
            target="_blank"
            rel="noopener"
          >
            www.nextfinancial.com/customers/disclosures</a
          >.
        </p>

        <p *ngSwitchCase="'WIS'">
          For additional information, please visit our website:
          <a href="https://www.wisdirect.com/disclosures" target="_blank" rel="noopener">
            www.wisdirect.com/disclosures</a
          >.
        </p>

         <p *ngSwitchCase="'SCF'">
          For additional information, please visit our website:
          <a href="https://www.scfsecurities.com/disclosures/" target="_blank" rel="noopener">
            https://www.scfsecurities.com/disclosures/</a
          >.
        </p>

        <p *ngSwitchDefault>
          For additional information, please visit our website:
          <a href="https://www.cusonet.com/disclosures/" target="_blank" rel="noopener"
            >www.cusonet.com/disclosures/</a
          >
        </p>
      </div>
    </div>

    <p>
      Contents Copyright &copy; {{ copyrightYear }}
      <span
        *ngIf="
          brokerDealerCode !== 'CG' && brokerDealerCode !== 'NEXT' && brokerDealerCode !== 'WIS' && brokerDealerCode !== 'SCF'
        "
        >CUSO Financial Services, LP/Sorrento Pacific Financial, LLC.</span
      >
      <span *ngIf="brokerDealerCode === 'CG'">Cadaret, Grant & Co., Inc.</span>
      <span *ngIf="brokerDealerCode === 'NEXT'">NEXT Financial Group, Inc.</span>
      <span *ngIf="brokerDealerCode === 'WIS'">Western International Securities, Inc.</span>
      <span *ngIf="brokerDealerCode === 'SCF'">SCF Securities, Inc.</span>
    </p>
  </div>
</footer>
