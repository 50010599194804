<header class="absolute top-0 right-0 left-0 hidden xl:block no-printer">
  <section class="header bg-primary"></section>
  <section class="nav bg-white border-b border-gray-300"></section>
</header>

<router-outlet></router-outlet>

<mpv-workgroup-theme></mpv-workgroup-theme>
<mpv-survicate></mpv-survicate>

<mpv-qr-modal></mpv-qr-modal>
