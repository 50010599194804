import { Component, OnInit } from '@angular/core'
import { QrModalService } from '../../services/qr-modal.service'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'mpv-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.sass']
})
export class QrModalComponent implements OnInit {
  appStoreLink: string
  playStoreLink: string

  constructor(public QRModal: QrModalService, public deviceService: DeviceDetectorService) {
    this.appStoreLink = 'https://apps.apple.com/us/app/clear1/id1625702898'
    this.playStoreLink = 'https://play.google.com/store/apps/details?id=com.orcasnet.mpv'
  }

  ngOnInit() {}

  close() {
    this.QRModal.hide()
  }

  scrollContent() {
    setTimeout(() => {
      const classElement = document.getElementsByClassName('p-dialog-content')
      if (classElement.length > 0) {
        classElement[0].scrollTo({ top: 0 })
      }
    }, 100)
  }
}
