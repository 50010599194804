import { CurrencyPipe } from '@angular/common'
import { dateFormat } from 'highcharts'
import { groupBy, values } from 'lodash-es'

import { pluralize } from '../functions'
import { Deserializable } from './deserializable.model'
import { Transaction, TransactionType } from './transaction.model'

export class TransactionGroupChartDataPoint implements Deserializable {
  private static currencyPipe = new CurrencyPipe('en-US')

  date: Date
  withdrawals: Transaction[] = []
  contributions: Transaction[] = []

  get type(): TransactionType | 'mixed' {
    const totalWithdrawals = this.withdrawals.length
    const totalContributions = this.contributions.length
    let output: TransactionType | 'mixed' = 'mixed'

    if (totalWithdrawals && !totalContributions) output = TransactionType.Withdrawal
    else if (!totalWithdrawals && totalContributions) output = TransactionType.Contribution

    return output
  }

  get totalWithdrawalDollars(): string {
    const sum = TransactionGroupChartDataPoint.sumTransactionAmounts(this.withdrawals)
    return TransactionGroupChartDataPoint.toDollars(sum)
  }

  get totalContributionDollars(): string {
    const sum = TransactionGroupChartDataPoint.sumTransactionAmounts(this.contributions)
    return TransactionGroupChartDataPoint.toDollars(sum)
  }

  // For charting
  readonly zIndex = 5
  readonly y = 0
  get x(): Date {
    return this.date
  }
  get value(): Date {
    return this.date
  }
  get className(): string {
    return `mpv-transaction-${this.type}`
  }
  get tooltip(): string {
    const { date, contributions, withdrawals } = this
    let output = `<b>${dateFormat('%b %e, %Y', +date)}</b><br>`

    if (contributions.length) {
      output +=
        `${pluralize('contribution', contributions.length, true)}: ` +
        `<b>${this.totalContributionDollars}</b><br>`
    }

    if (withdrawals.length) {
      output +=
        `${pluralize('withdrawal', withdrawals.length, true)}: ` +
        `<b>${this.totalWithdrawalDollars}</b><br>`
    }

    return output
  }

  static deserializePoints(
    transactions: api.contributionWithdrawalDates
  ): TransactionGroupChartDataPoint[] {
    const transactionsByDate = values(groupBy(transactions, 'date'))
    return transactionsByDate.map(transactionGroup =>
      new TransactionGroupChartDataPoint().deserialize(transactionGroup)
    )
  }

  static sumTransactionAmounts(transactions: Transaction[]): number {
    return transactions.reduce((accumulator, transaction) => transaction.amount + accumulator, 0)
  }

  static toDollars(amount: number): string {
    return this.currencyPipe.transform(amount, 'USD', 'symbol', '1.2-2')
  }

  // API doesn't provide the transactions grouped by date. Yet. So they need to be be run through
  // TransactionGroupChartDataPoint.deserializePoints to get grouped, which then call this.
  deserialize(transactionsByDate: api.contributionWithdrawalDates): this {
    transactionsByDate.map(transaction => {
      this.addTransaction(new Transaction().deserialize(transaction))
    })

    return this
  }

  addTransaction(transaction: Transaction): void {
    // If this group doesn't have a date set yet, set it
    if (!this.date) this.date = transaction.date
    // Guard against having transactions of differing dates
    if (+transaction.date !== +this.date) throw Error(`Can't group transactions of different dates`)
    // Sort it into the correct bucket
    if (transaction.type === TransactionType.Contribution) {
      this.contributions.push(transaction)
    } else {
      this.withdrawals.push(transaction)
    }
  }
}
