import { Component, HostBinding, Input } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

// IE SVG scaling bug hack
// http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/

/**
 * ADA-106: Add focusable="false" to svg, to prevent Internet Explorer
 * from including SVG in tabindex.
 */
@Component({
  selector: 'mpv-icon',
  templateUrl: './icon.component.html',
  styles: [
    `
      :host,
      div {
        display: inline-flex;
      }
      div {
        position: relative;
      }

      canvas {
        display: block;
        height: 100%;
        visibility: hidden;
      }

      svg {
        fill: currentColor;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    `
  ]
})
export class IconComponent {
  private static count = 0

  // Convert icon names from camelCase to kebab-case
  id: string
  iconId: string
  iconName: string
  paths: SafeHtml

  @Input()
  alt: string

  @Input()
  height: string

  @Input()
  roletype = 'img'

  @HostBinding('class')
  hostClasses = 'icon'

  constructor(private sanitizer: DomSanitizer) {
    this.id = 'icon-' + IconComponent.count++
  }

  @Input()
  private set icon(icon: string) {
    this.iconId = icon
    this.iconName = icon.replace(/-/g, ' ')
    this.hostClasses = 'icon icon-' + icon
  }
}
