<p-dialog
  id="QRModal"
  [closeOnEscape]="false"
  [blockScroll]="true"
  [showHeader]="true"
  [draggable]="false"
  [(visible)]="QRModal.showQRModal"
  [modal]="true"
  [style]="{ top: '50px' }"
  [styleClass]="'module'"
  [dismissableMask]="true"
  (onShow)="scrollContent()"
  (onHide)="close()"
>
  <p-header> DOWNLOAD THE APP</p-header>

  <div>
    <div class="px-4 text-center">
      <p class="semi">App free on Android and iOS devices</p>
      <p class="medium" *ngIf="deviceService.isDesktop()">
        Scan the QR code below for your iPhone or Android phone<br class="hidden lg:block" />
        by opening up the camera and clicking the link
      </p>
    </div>
    <div class="flex w-full justify-evenly my-6 flex-wrap md:flex-nowrap">
      <div
        id="android"
        class="flex flex-col items-center gap-5 w-full md:w-1/2"
        [class.mb-8]="!deviceService.isDesktop()"
      >
        <ng-container *ngIf="deviceService.isDesktop()">
          <span class="flex justify-center items-center"
            ><mpv-icon icon="android"> </mpv-icon>Android</span
          >
          <div class="container-QR">
            <img class="qr" src="/assets/images/QR_playstore.png" />
          </div>
        </ng-container>

        <a [href]="playStoreLink" target="_blank">
          <div class="container-logo"><img src="/assets/images/play_store.png" /></div>
        </a>
      </div>

      <div class="vertical" *ngIf="deviceService.isDesktop()"></div>

      <div id="apple" class="flex flex-col items-center gap-5 w-full md:w-1/2">
        <ng-container *ngIf="deviceService.isDesktop()">
          <span class="flex justify-center items-center"
            ><mpv-icon icon="apple"></mpv-icon>iOS</span
          >
          <div class="container-QR">
            <img class="qr" src="/assets/images/QR_appstore.png" />
          </div>
        </ng-container>

        <a [href]="appStoreLink" target="_blank">
          <div class="container-logo"><img src="/assets/images/app_store.png" /></div>
        </a>
      </div>
    </div>
    <div class="action-container flex w-full justify-end">
      <button class="btn-close btnprimary unstyledbtn" (click)="close()">Close</button>
    </div>
  </div>
</p-dialog>
