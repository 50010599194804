import { Injectable } from '@angular/core'

export type logLevel = 'DISABLE' | 'ERROR' | 'WARN' | 'INFO'

@Injectable()
export class ConsoleLogger {
  logLevel: logLevel = 'DISABLE'
  logPrefix = '[App]'

  log(...messages) {
    // disable-next-line no-console
    if (this.getLogLevelValue() > 2) console.log(this.logPrefix, ...messages)
  }

  warn(...messages) {
    // eslint-disable-next-line no-console
    if (this.getLogLevelValue() > 1) console.warn(this.logPrefix, ...messages)
  }

  error(...messages) {
    // eslint-disable-next-line no-console
    if (this.getLogLevelValue() > 0) console.error(this.logPrefix, ...messages)
  }

  private getLogLevelValue() {
    switch (this.logLevel) {
      case 'INFO':
        return 3
      case 'WARN':
        return 2
      case 'ERROR':
        return 1
      default:
        return 0
    }
  }
}
