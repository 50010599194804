interface Options {
  longYear: boolean
}

const quantizeStringToMonths = {
  month: 1,
  quarter: 3,
  year: 12
}

export function quantizedDateFormat (date: Date, quantization: string | number, options: Options = { longYear: false }): string {
  if (typeof quantization === 'string') quantization = quantizeStringToMonths[quantization]
  const year = date.getFullYear() + ''
  const month = date.getMonth() + 1
  const quarter = Math.ceil(month / 3)
  const shortYear = year.slice(2)
  const shortMonth = date.toString().split(' ')[1]

  let displayYear = `'` + shortYear

  if (options.longYear) displayYear = year

  if (quantization === 1) return `${shortMonth} ${displayYear}`
  if (quantization === 3) return `Q${quarter} ${displayYear}`
  if (quantization === 12) return year
}
