import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router'
import { Observable } from 'rxjs/internal/Observable'
import { map, switchMap, take } from 'rxjs/operators'
import { UserSessionService } from './user-session.service'

@Injectable()
export class NoRestrictionGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private userSession: UserSessionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can(route)
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can(childRoute)
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.userSession.isAuthenticated$.pipe(
      take(1),
      switchMap(() => this.userSession.currentSession$),
      map(userSession => userSession && userSession.restrictions.hasNone)
    )
  }

  private can(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.userSession.isAuthenticated$.pipe(
      take(1),
      switchMap(() => this.userSession.currentSession$),
      map(user => {
        if (user == null) return false
        if (user.restrictions.hasNone) return true
        return this.router.parseUrl(`/login/restriction/${user.restrictions.nextRestriction}`)
      })
    )
  }
}
