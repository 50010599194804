import { ErrorHandler, Injectable } from '@angular/core'
import * as Honeybadger from 'honeybadger-js'

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  logToHoneybadger = true

  constructor() {
    super()
  }

  setContext = Honeybadger.setContext
  resetContext = Honeybadger.resetContext
  private notify = Honeybadger.notify

  handleError(error) {
    if (this.logToHoneybadger) this.notify(error)
    super.handleError(error)
  }
}
