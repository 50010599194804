import { parseIsoDate } from '../functions'
import { Deserializable } from './deserializable.model'

export enum TransactionType {
  Withdrawal = 'withdrawal',
  Contribution = 'contribution'
}

export class Transaction implements Deserializable {
  date: Date
  account_number: string
  type: TransactionType
  amount: number

  deserialize(input: any): this {
    const { date, account_number, type, amount } = input
    // Normalize data
    this.date = parseIsoDate(date)
    this.account_number = account_number
    this.type = type.toLowerCase()
    this.amount = Number(amount)

    return this
  }
}
