import { Deserializable } from './deserializable.model'
import { environment } from '../../../environments/environment'

export class Workgroup implements Deserializable {
  static readonly COBRANDED_BROKER_DEALERS = ['CFS', 'SPF', 'CG', 'NEXT']
  static readonly SHOW_BROKER_DEALERS = [
    '003',
    '036',
    '333',
    '394',
    '398',
    '638',
    '669',
    '677',
    '678',
    '697',
    '804'
  ]

  
  static readonly SHOW_SINGLE_LOGO = ['BDCU', 'D', 'WAB','GWPART']

  code: string
  name: string
  realm_code: string
  broker_dealer_code: string
  abbreviated_name: string
  albridge_enable_date: string
  mpv_disclaimer: string
  mpv_logout_url: string
  is_mobile_app_enabled: boolean
  primary_color: string
  secondary_color: string
  mpv3_direct_login: string
  refactored: boolean

  get isCobranded(): boolean {
    return (
      Workgroup.COBRANDED_BROKER_DEALERS.indexOf(this.broker_dealer_code) > -1 &&
      this.isCGWorkgroup &&
      !this.isNEXTsWorkgroup() &&
      !this.getSingleLogoWorkgroup
    )
  }

  get isCGWorkgroup(): boolean {
    return this.realm_code.toLowerCase() !== 'cg'
      ? true
      : Workgroup.SHOW_BROKER_DEALERS.indexOf(this.code) > -1
  }

  isNEXTsWorkgroup(): boolean {
    return this.realm_code.toLowerCase() === 'next'
  }

  get getSingleLogoWorkgroup(): boolean {
    return Workgroup.SHOW_SINGLE_LOGO.indexOf(this.code) > -1
  }

  getLogoUrl(): string {
    const filename = this.isCobranded ? 'mpv_web' : 'mpv_web_bd'
    const baseUrl = ['development', 'staging'].includes(environment.name) ? `https://dv-day-old.orcasnet.com/api/v1` : 'https://assets.orcasnet.com'

    return `${baseUrl}/realms/${this.realm_code}/workgroups/${
      this.code
    }/${filename}.png`
  }

  getBrokerDealerLogoUrl(): string {
    return `/assets/images/${this.realm_code}-logo.svg`
  }

  deserialize(input: any): this {
    Object.assign(this, input)
    return this
  }
}
