import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

// Vendor
import * as Honeybadger from 'honeybadger-js'

// App
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { VERSION } from './environments/version'

// PRE-BOOTSTRAP
if (environment.debug.errorReporter) window.onerror = () => {}

Honeybadger.configure({
  environment: environment.name,
  revision: VERSION.hash,
  debug: environment.debug.errorReporter,
  onerror: environment.isProduction,
  apiKey: '5337adca'
  // host: 'err.orcasnet.com' // TODO: Use this host when proxying to api.honeybadger.io is configured.
})

if (environment.isProduction) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
