import { Deserializable } from './deserializable.model'

export enum Restriction {
  VerifyEmail = 'verify_email',
  ChangePassword = 'change_password',
  LegacyMfa = 'answer_legacy_mfa_question',
  CreateUsername = 'create_username',
  SetupOtp = 'setup_otp',
  VerifyDevice = 'verify_device',
  VerifyMobile = 'verify_mobile'
}

export class SessionRestrictionsModel implements Deserializable {
  private restrictions: Restriction[] = []

  get hasAny(): boolean {
    return this.restrictions.length > 0
  }

  get hasNone(): boolean {
    return this.restrictions.length === 0
  }

  get nextRestriction(): undefined | Restriction {
    if (this.hasAny) {
      return this.restrictions[0]
    }
  }

  deserialize(restrictions: Restriction[]): this {
    this.restrictions = restrictions || []
    // this.restrictions = [Restriction.LegacyMfa] // Mock a restriction
    return this
  }
}
