import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { DialogModule } from 'primeng/dialog'
import { InputMaskModule } from 'primeng/inputmask'

import {
  BrandingComponent,
  DatePickerComponent,
  DisclaimerComponent,
  IconComponent,
  Clear1SupportHotlineComponent,
  PhoneNumberComponent,
  TooltipComponent
} from './components'
import { SortColumnDirective, SortDirective } from './directives'
import {
  AbbreviateCurrencyPipe,
  DecodeBuySellPipe,
  HasControlErrorPipe,
  JoinBrPipe,
  SimpleZeroPipe,
  SuffixPipe,
  SumKeyPipe,
  TransactionTypePipe,
  OtpMethodToEnglishPipe,
  OrDashPipe,
  KeysPipe
} from './pipes'
import { CacheInterceptorService, AuthenticityInterceptor } from './services'
import { FileDropDirective } from './directives/file-drop.directive'
import { AppHeaderComponent } from '../app-header/app-header.component'
import { NoValuePipe } from './directives/no-value.pipe'
import { NewLineToBrModule } from './pipes/new-line-to-br/new-line-to-br.module'
import { QrModalComponent } from './components/qr-modal/qr-modal.component'
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component'
import { DeviceDetectorModule } from 'ngx-device-detector'

import { WordpressInterceptor } from './interceptors/wordpress.interceptor'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    DialogModule,
    InputMaskModule,
    NewLineToBrModule,
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    // Components
    AppHeaderComponent,
    BrandingComponent,
    DatePickerComponent,
    DisclaimerComponent,
    IconComponent,
    Clear1SupportHotlineComponent,
    PhoneNumberComponent,
    TooltipComponent,
    QrModalComponent,
    ImageCropperComponent,
    // Directives
    SortDirective,
    SortColumnDirective,
    // Pipes
    AbbreviateCurrencyPipe,
    DecodeBuySellPipe,
    HasControlErrorPipe,
    KeysPipe,
    OrDashPipe,
    JoinBrPipe,
    SimpleZeroPipe,
    SuffixPipe,
    SumKeyPipe,
    TransactionTypePipe,
    OtpMethodToEnglishPipe,
    FileDropDirective,
    NoValuePipe
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticityInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WordpressInterceptor, multi: true }
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DialogModule,
    InputMaskModule,
    // Components
    BrandingComponent,
    DatePickerComponent,
    DisclaimerComponent,
    IconComponent,
    Clear1SupportHotlineComponent,
    PhoneNumberComponent,
    TooltipComponent,
    QrModalComponent,
    ImageCropperComponent,
    // Directives
    SortDirective,
    SortColumnDirective,
    // Pipes
    AbbreviateCurrencyPipe,
    DecodeBuySellPipe,
    HasControlErrorPipe,
    KeysPipe,
    OrDashPipe,
    JoinBrPipe,
    SimpleZeroPipe,
    SuffixPipe,
    SumKeyPipe,
    TransactionTypePipe,
    OtpMethodToEnglishPipe,
    FileDropDirective,
    NoValuePipe,
    AppHeaderComponent,
    NewLineToBrModule
  ]
})
export class SharedModule {}
