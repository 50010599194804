import { fromEvent } from 'rxjs'
import { distinctUntilChanged, filter, withLatestFrom } from 'rxjs/operators'
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UserSessionService } from '../../services'
import { WindowBreakpoints } from '../../../shared/enums/breakpoints.enum'

@UntilDestroy()
@Component({
  selector: 'mpv-survicate',
  template: ''
})
export class SurvicateComponent implements OnInit, OnDestroy {
  private survicateTraits

  constructor(private userService: UserSessionService, @Inject('WINDOW') private window: Window) {}

  ngOnInit(): void {
    ;(function(w) {
      const s = document.createElement('script')
      s.src = '//survey.survicate.com/workspaces/GLZanfwvkodkzMYuIAYGDiuKCHkrjiky/web_surveys.js'
      s.async = true
      const e = document.getElementsByTagName('script')[0]
      e.parentNode.insertBefore(s, e)
    })(this.window)

    this.userService.currentSession$
      .pipe(
        filter(user => !!user),
        distinctUntilChanged((userA, userB) => {
          return userA.user_id === userB.user_id
        }),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.survicateTraits = {
          user_id: user.user_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          workgroup_code: user.workgroup_code
        }
        if (this.window['_sva']) {
          this.window['_sva'].destroyVisitor(this.window['_sva'].retarget)
          this.survicateTraits.user_id = user.user_id
          this.window['_sva'].setVisitorTraits(this.survicateTraits)
        }
      })

    fromEvent(this.window, 'SurvicateReady')
      .pipe(withLatestFrom(this.userService.currentSession$))
      .subscribe(([first, user]) => {
        if (user) {
          this.survicateTraits = {
            user_id: user.user_id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            workgroup_code: user.workgroup_code
          }
          this.window['_sva'].setVisitorTraits(this.survicateTraits)
          this.window['_sva'].addEventListener('survey_completed', () => {
            this.window['_sva'].destroyVisitor(this.window['_sva'].retarget)
            // re-set the user_id since survicate deletes the property after setting visitor traits
            this.survicateTraits.user_id = user.user_id
            this.window['_sva'].setVisitorTraits(this.survicateTraits)
          })
          this.window['_sva'].addEventListener('survey_closed', surveyId => {
            this.window['_sva'].destroyVisitor(this.window['_sva'].retarget)
            this.survicateTraits.user_id = user.user_id
            this.window['_sva'].setVisitorTraits(this.survicateTraits)
          })
        }

        setTimeout(() => {
          const survicate = document.getElementById('survicate-box')

          if (survicate) {
            survicate.style.display = window.screen.width >= WindowBreakpoints.md ? 'block' : 'none'
          }
        })
      })
  }

  ngOnDestroy() {}

  @HostListener('window:resize', ['$event']) onResize(event) {
    document.getElementById('survicate-box').style.display =
      event.target.innerWidth >= WindowBreakpoints.md ? 'block' : 'none'
  }
}
