import { FormGroup } from '@angular/forms'

export function valuesMatchValidator(primaryControlName: string, confirmationControlName: string) {
  return (group: FormGroup): void => {
    const ctrlOne = group.get(primaryControlName)
    const ctrlTwo = group.get(confirmationControlName)
    const isMismatch = ctrlOne.value !== ctrlTwo.value

    const error = !ctrlTwo.value ? { required: true } : isMismatch ? { valuesMatch: true } : null
    ctrlTwo.setErrors(error)
  }
}
