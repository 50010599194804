<ng-container [ngSwitch]="iconId">
  <div class="no-printer" [ngStyle]="{ height: height || '16px' }">
    <canvas width="16" height="16"></canvas>
    <svg
      version="1.1"
      [attr.role]="roletype"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      focusable="false"
      [attr.aria-labelledby]="id"
      [attr.aria-label]="alt"
      [attr.height]="height || '16px'"
    >
      <title [id]="id">{{ alt || iconName }}</title>
      <path
        *ngSwitchCase="'info'"
        d="M8,0c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S3.6,0,8,0z M8,1.5C4.4,1.5,1.5,4.4,1.5,8
        s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5S11.6,1.5,8,1.5L8,1.5z M9.1,8c0-0.3-0.1-0.5-0.4-0.8C8.6,7,8.3,6.9,8,6.9H6.9
        C6.5,6.9,6.3,7,6.1,7.2C5.8,7.5,5.7,7.7,5.7,8h1.1v3.4c0,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.4,0.8,0.4h1.1c0.3,0,0.5-0.1,0.8-0.4
        c0.2-0.2,0.3-0.5,0.4-0.8H9.1V8L9.1,8z M7.2,5.4C7,5.2,6.9,4.9,6.9,4.6C6.9,4.3,7,4,7.2,3.8C7.4,3.6,7.7,3.5,8,3.5
        c0.3,0,0.6,0.1,0.8,0.3s0.3,0.5,0.3,0.8S9,5.2,8.8,5.4C8.6,5.6,8.3,5.7,8,5.7C7.7,5.7,7.4,5.6,7.2,5.4z"
      />

      <path
        *ngSwitchCase="'filter'"
        d="M2.7,8.9V7.1h10.7v1.8H2.7z M0,2.7h16v1.8H0V2.7z M6.2,13.3v-1.8h3.6v1.8H6.2z"
      />

      <path
        *ngSwitchCase="'accounts'"
        fill-rule="evenodd"
        d="M15.1,4.5V3.1H14V1.9H0v10.2h1.1v1.1h1.4v0.9H16V4.5H15.1z M0.6,11.5v-9h12.8v0.5H1.1v8.4H0.6z M1.7,12.6v-9
        h12.8v0.8h-12v8.1H1.7z M4.5,11.4c0-1.1,0.9-1.9,1.9-1.9C5.8,9.5,5.3,9,5.3,8.3s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S7.1,9.5,6.4,9.5
        c1.1,0,1.9,0.9,1.9,1.9C7.2,11.4,5.7,11.4,4.5,11.4z M14.2,11.5h-4.1v-0.6h4.1V11.5z M14.2,9.6h-4.1V9h4.1V9.6z M14.2,7.7h-4.1V7.1
        h4.1V7.7z"
      />

      <g *ngSwitchCase="'accounts-alt'">
        <path
          d="m 12.432627,7.972852 c -1.409239,0 -2.5516909,0.527742 -2.5516909,1.178735 v 0.255639 c 0,0.651044 1.1424519,1.178786 2.5516909,1.178786 1.409239,0 2.55169,-0.527742 2.55169,-1.178786 V 9.151587 c 0,-0.650993 -1.142451,-1.178735 -2.55169,-1.178735 z"
        />
        <path
          d="m 12.432627,11.16351 c -1.230142,0 -2.256826,-0.402116 -2.4980133,-0.937342 -0.035097,0.07794 -0.05368,0.158658 -0.05368,0.241444 v 0.255639 c 0,0.650994 1.1424523,1.178736 2.5516903,1.178736 1.409239,0 2.55169,-0.527742 2.55169,-1.178736 v -0.255639 c 0,-0.08279 -0.01858,-0.163509 -0.05368,-0.241444 -0.241187,0.535226 -1.26787,0.937342 -2.498012,0.937342 z"
        />
        <path
          d="m 12.432627,12.479587 c -1.230142,0 -2.256826,-0.402117 -2.4980133,-0.937291 -0.035097,0.07794 -0.05368,0.158658 -0.05368,0.241394 v 0.255639 c 0,0.651045 1.1424523,1.178787 2.5516903,1.178787 1.409239,0 2.55169,-0.527742 2.55169,-1.178787 V 11.78369 c 0,-0.08274 -0.01858,-0.163458 -0.05368,-0.241394 -0.241187,0.535174 -1.26787,0.937291 -2.498012,0.937291 z"
        />
        <path
          d="m 12.432627,13.866632 c -1.230142,0 -2.256826,-0.402116 -2.4980133,-0.937342 -0.035097,0.07799 -0.05368,0.158658 -0.05368,0.241445 v 0.255639 c 0,0.651045 1.1424523,1.178736 2.5516903,1.178736 1.409239,0 2.55169,-0.527691 2.55169,-1.178736 v -0.255639 c 0,-0.08279 -0.01858,-0.163509 -0.05368,-0.241445 -0.241187,0.535175 -1.26787,0.937342 -2.498012,0.937342 z"
        />
        <path
          d="m 12.432627,15.182607 c -1.230142,0 -2.256826,-0.402117 -2.4980133,-0.937291 -0.035097,0.07794 -0.05368,0.158658 -0.05368,0.241394 v 0.255638 c 0,0.650994 1.1424523,1.178736 2.5516903,1.178736 1.409239,0 2.55169,-0.527742 2.55169,-1.178736 v -0.25569 c 0,-0.08274 -0.01858,-0.163458 -0.05368,-0.241394 -0.241187,0.535226 -1.26787,0.937343 -2.498012,0.937343 z"
        />
        <path
          d="M 12.647027,0 H 4.6470475 C 4.2227017,0 3.815734,0.1685707 3.5156772,0.4686293 3.2156198,0.7686867 3.047049,1.1756542 3.047049,1.6 v 12.8 c 0,0.424362 0.1685708,0.831329 0.4686282,1.131355 C 3.815734,15.831432 4.2227017,16 4.6470475,16 H 10.016833 C 9.4558521,15.567174 8.9854008,15.022762 8.6387169,14.4 H 7.8470475 v -1.6 h 0.2191793 c -0.054913,-0.303536 -0.083561,-0.616207 -0.083561,-0.935587 0,-0.225084 0.014247,-0.446813 0.04186,-0.664413 H 7.8470495 V 9.6 H 8.498022 C 9.0283967,8.499974 9.9332221,7.614451 11.047029,7.108696 V 6.4 h 1.6 v 0.273342 c 0.182709,-0.0193 0.368154,-0.02927 0.555974,-0.02927 0.357523,0 0.706684,0.03597 1.044026,0.104465 V 1.6 c 0,-0.4243458 -0.168568,-0.8313133 -0.468594,-1.1313707 C 13.478355,0.1685707 13.071388,0 12.647027,0 Z M 4.6470475,4.8 V 1.6 h 7.9999795 v 3.2 z m 0,3.2 V 6.4 h 1.6 V 8 Z m 3.2,0 V 6.4 H 9.4470267 V 8 Z m -3.2,3.2 V 9.6 h 1.6 v 1.6 z m 0,3.2 v -1.6 h 1.6 v 1.6 z"
        />
      </g>

      <g *ngSwitchCase="'calendar'">
        <rect x="9.7" y="5.7" class="st0" width="1.1" height="1.1" />
        <rect x="9.7" y="8" class="st0" width="1.1" height="1.1" />
        <rect x="9.7" y="10.3" class="st0" width="1.1" height="1.1" />
        <rect x="9.7" y="12.6" class="st0" width="1.1" height="1.1" />
        <rect x="7.4" y="5.7" class="st0" width="1.1" height="1.1" />
        <rect x="12" y="5.7" class="st0" width="1.1" height="1.1" />
        <rect x="12" y="10.3" class="st0" width="1.1" height="1.1" />
        <rect x="4" class="st0" width="1.1" height="2.3" />
        <rect x="7.4" y="8" class="st0" width="1.1" height="1.1" />
        <rect x="12" y="8" class="st0" width="1.1" height="1.1" />
        <rect x="10.9" class="st0" width="1.1" height="2.3" />
        <rect x="2.9" y="8" class="st0" width="1.1" height="1.1" />
        <rect x="2.9" y="10.3" class="st0" width="1.1" height="1.1" />
        <rect x="2.9" y="12.6" class="st0" width="1.1" height="1.1" />
        <rect x="7.4" y="10.3" class="st0" width="1.1" height="1.1" />
        <rect x="7.4" y="12.6" class="st0" width="1.1" height="1.1" />
        <rect x="5.1" y="5.7" class="st0" width="1.1" height="1.1" />
        <rect x="5.1" y="12.6" class="st0" width="1.1" height="1.1" />
        <rect x="5.1" y="10.3" class="st0" width="1.1" height="1.1" />
        <rect x="5.1" y="8" class="st0" width="1.1" height="1.1" />
        <path
          class="st0"
          d="M14.3,1.1h-1.1v1.7c0,0.3-0.3,0.6-0.6,0.6h-2.3c-0.3,0-0.6-0.3-0.6-0.6V1.1H6.3v1.7c0,0.3-0.3,0.6-0.6,0.6H3.4
          c-0.3,0-0.6-0.3-0.6-0.6V1.1H1.7c-0.6,0-1.1,0.5-1.1,1.1v12.6c0,0.6,0.5,1.1,1.1,1.1h12.6c0.6,0,1.1-0.5,1.1-1.1V2.3
          C15.4,1.7,14.9,1.1,14.3,1.1z M14.3,14.9H1.7V4.6h12.6V14.9z"
        />
      </g>

      <g *ngSwitchCase="'chevron-down'">
        <polygon points="8,12.8 0,4.8 1.6,3.2 8,9.7 14.5,3.2 16,4.8 " />
      </g>

      <g *ngSwitchCase="'chevron-up'">
        <polygon transform="rotate(180 8 8)" points="8,12.8 0,4.8 1.6,3.2 8,9.7 14.5,3.2 16,4.8 " />
      </g>

      <g *ngSwitchCase="'chevron-right'">
        <polygon points="12.8,8 4.8,16 3.2,14.4 9.7,8 3.2,1.5 4.8,0 " />
      </g>

      <g *ngSwitchCase="'double-chevron-left'">
        <polygon points="6.7,13.2 0,8 6.7,2.8 8,3.7 2.4,8 8,12.3" />
        <polygon points="14.8,13.2 8.1,8 14.8,2.8 16,3.7 10.5,8 16,12.3" />
      </g>

      <g *ngSwitchCase="'double-chevron-right'">
        <polygon points="9.3,2.8 16,8 9.3,13.2 8,12.3 13.6,8 8,3.7" />
        <polygon points="1.2,2.8 7.9,8 1.2,13.2 0,12.3 5.5,8 0,3.7" />
      </g>

      <path
        *ngSwitchCase="'edit'"
        d="M15.7,3.9l-1.5,1.5l-3.5-3.5l1.5-1.5c0.5-0.5,1.2-0.5,1.7,0l0,0l1.9,1.9C16.1,2.7,16.1,3.4,15.7,3.9z M3.5,14.8
	      H1.2v-2.4h1.2v1.2h1.2V14.8z M0,12.5V16h3.5l9.4-9.4L9.4,3.1L0,12.5z"
      />

      <g *ngSwitchCase="'ellipsis'">
        <path d="M16,8c0,1-0.8,1.8-1.8,1.8S12.3,9,12.3,8s0.8-1.8,1.8-1.8S16,7,16,8" />
        <path d="M8,9.8C9,9.8,9.8,9,9.8,8S9,6.2,8,6.2C7,6.2,6.2,7,6.2,8S7,9.8,8,9.8" />
        <path d="M1.8,9.8c1,0,1.8-0.8,1.8-1.8S2.9,6.2,1.8,6.2S0,7,0,8S0.8,9.8,1.8,9.8" />
      </g>

      <path
        *ngSwitchCase="'email'"
        d="M14.9,11.5l-4.6-3.5l4.6-3.5V11.5z M2.3,12.1l4-3.5L8,10.4l1.7-1.7l4,3.5H2.3z M1.1,4.6
        l4.6,3.5l-4.6,3.5V4.6z M14.9,2.9L8,8.7L1.1,2.9H14.9z M0,2.9v9.2c0,0.6,0.5,1.2,1.1,1.2h13.7c0.6,0,1.1-0.5,1.1-1.2V2.9
        c0-0.6-0.5-1.2-1.1-1.2H1.1C0.5,1.7,0,2.2,0,2.9z"
      />

      <path
        *ngSwitchCase="'grid-view'"
        d="M11.3,7.4H16V0h-4.7V7.4z M5.6,7.4h4.7V0H5.6V7.4z M0,7.4h4.7V0H0V7.4z M11.3,16H16V8.6h-4.7V16
        z M5.6,16h4.7V8.6H5.6V16z M0,16h4.7V8.6H0V16z"
      />

      <path
        *ngSwitchCase="'list-view'"
        d="M15.2,13.3H6.1c-0.8,0-0.8,0.5-0.8,1.3s0,1.3,0.8,1.3h9.1c0.8,0,0.8-0.5,0.8-1.3
        S16,13.3,15.2,13.3L15.2,13.3z M15.2,6.7H6.1C5.3,6.7,5.3,7.2,5.3,8s0,1.3,0.8,1.3h9.1C16,9.3,16,8.8,16,8S16,6.7,15.2,6.7L15.2,6.7
        z M1.9,0H0.8C0,0,0,0.5,0,1.3s0,1.3,0.8,1.3h1.1c0.8,0,0.8-0.5,0.8-1.3S2.7,0,1.9,0L1.9,0z M1.9,6.7H0.8C0,6.7,0,7.2,0,8
        s0,1.3,0.8,1.3h1.1c0.8,0,0.8-0.5,0.8-1.3S2.7,6.7,1.9,6.7L1.9,6.7z M6.1,2.7h9.1C16,2.7,16,2.1,16,1.3S16,0,15.2,0H6.1
        C5.3,0,5.3,0.5,5.3,1.3S5.3,2.7,6.1,2.7z M2.7,14.7c0,0.8,0,1.3-0.8,1.3H0.8C0,16,0,15.5,0,14.7s0-1.3,0.8-1.3h1.1
        C2.7,13.3,2.7,13.9,2.7,14.7L2.7,14.7z"
      />

      <path
        *ngSwitchCase="'mobile-auth'"
        d="M5,7.3H2.8V6.2c0-0.6,0.5-0.9,1.1-0.9S5,5.6,5,6.2V7.3z M5.9,7.3V6.2c0-1-1-1.8-2-1.8
        s-2,0.8-2,1.8v1.1C1.4,7.3,1,7.7,1,8.1v2.5c0,0.5,0.4,0.9,0.9,0.9h4c0.5,0,0.9-0.4,0.9-0.9V8.2C6.8,7.7,6.4,7.3,5.9,7.3z M11.9,0
        H4.6C3.8,0,3.2,0.7,3.2,1.5v2.2h1.5V2.2h7.3v11.6H4.6v-1.5H3.2v2.2c0,0.8,0.7,1.5,1.5,1.5h7.3c0.8,0,1.5-0.7,1.5-1.5V1.5
        C13.4,0.7,12.7,0,11.9,0z"
      />

      <path
        *ngSwitchCase="'phone'"
        d="M10.7,8c0-0.5-0.1-0.9-0.4-1.3c-0.2-0.4-0.6-0.7-1-1C8.9,5.5,8.5,5.3,8,5.3V3.5
        c0.8,0,1.6,0.2,2.2,0.6c0.7,0.4,1.2,0.9,1.6,1.6c0.4,0.7,0.6,1.4,0.6,2.2H10.7z M14.2,8c0-1.1-0.3-2.2-0.8-3.1
        c-0.6-0.9-1.3-1.7-2.2-2.2c-1-0.6-2-0.8-3.1-0.8V0c1.5,0,2.8,0.4,4,1.1c1.2,0.7,2.2,1.7,2.9,2.9C15.6,5.2,16,6.5,16,8H14.2z
        M15.1,11.1c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6v3.1c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-2,0-4-0.4-5.9-1.2
        C7.4,14,5.8,13,4.4,11.6S2,8.6,1.2,6.8C0.4,4.9,0,2.9,0,0.9c0-0.2,0.1-0.4,0.3-0.6S0.6,0,0.9,0H4c0.2,0,0.4,0.1,0.6,0.3
        c0.2,0.2,0.3,0.4,0.3,0.6C4.9,2,5,3.1,5.4,4c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.3-0.2,0.4l-2,2c0.6,1.3,1.5,2.4,2.5,3.4s2.1,1.8,3.4,2.5
        l2-2c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0,0.5,0C12.9,11,14,11.1,15.1,11.1z"
      />

      <path
        *ngSwitchCase="'search'"
        d="M6.4,11.4c-2.8,0-5-2.3-5-5s2.3-5,5-5s5,2.3,5,5S9.2,11.4,6.4,11.4z M15.7,14.2l-4.1-4.1
        c0.8-1.1,1.2-2.4,1.2-3.7C12.8,2.9,9.9,0,6.4,0S0,2.9,0,6.4c0,3.5,2.9,6.4,6.4,6.4c1.4,0,2.6-0.4,3.7-1.2l4.1,4.1
        c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.6-0.1,0.7-0.3C16.1,15.3,16.1,14.6,15.7,14.2L15.7,14.2L15.7,14.2z"
      />

      <path
        *ngSwitchCase="'settings'"
        d="M8,11.4c-1.9,0-3.4-1.5-3.4-3.4S6.1,4.6,8,4.6s3.4,1.5,3.4,3.4S9.9,11.4,8,11.4z M16,8.9V7.1
        l-2.2-0.7l-0.5-1.2l1-2.1L13,1.7l-2.1,1L9.7,2.2L8.9,0H7.1L6.3,2.2L5.1,2.7L3,1.7L1.7,3l1,2.1L2.2,6.3L0,7.1v1.8l2.2,0.7l0.5,1.2
        l-1,2.1L3,14.3l2.1-1l1.2,0.5L7.1,16h1.8l0.7-2.2l1.3-0.5l2.1,1l1.3-1.3l-1.1-2.1l0.5-1.2L16,8.9L16,8.9z"
      />

      <g *ngSwitchCase="'sortable'">
        <polygon class="icon-sortable-up" points="15.1,7.1 8,0 8.1,0.2 15,7 8,0 0.9,7.1 " />
        <polygon class="icon-sortable-down" points="0.9,8.9 8,16 7.9,15.8 1,9 8,16 15.1,8.9 " />
      </g>

      <path
        *ngSwitchCase="'close'"
        d="M 10.472656 5.527344 C 10.347656 5.402344 10.179688 5.328125 10 5.328125 C 9.820312 5.328125 9.652344 5.402344 9.527344 5.527344 L 8 7.058594 L 6.472656 5.527344 C 6.210938 5.265625 5.789062 5.265625 5.527344 5.527344 C 5.265625 5.789062 5.265625 6.210938 5.527344 6.472656 L 7.058594 8 L 5.527344 9.527344 C 5.402344 9.652344 5.328125 9.820312 5.328125 10 C 5.328125 10.179688 5.402344 10.347656 5.527344 10.472656 C 5.652344 10.597656 5.820312 10.671875 6 10.671875 C 6.179688 10.671875 6.347656 10.597656 6.472656 10.472656 L 8 8.941406 L 9.527344 10.472656 C 9.652344 10.597656 9.820312 10.671875 10 10.671875 C 10.179688 10.671875 10.347656 10.597656 10.472656 10.472656 C 10.597656 10.347656 10.671875 10.179688 10.671875 10 C 10.671875 9.820312 10.597656 9.652344 10.472656 9.527344 L 8.941406 8 L 10.472656 6.472656 C 10.597656 6.347656 10.671875 6.179688 10.671875 6 C 10.671875 5.820312 10.597656 5.652344 10.472656 5.527344 Z M 12.714844 3.285156 C 11.039062 1.554688 8.5625 0.859375 6.230469 1.46875 C 3.898438 2.078125 2.078125 3.898438 1.46875 6.230469 C 0.859375 8.5625 1.554688 11.039062 3.285156 12.714844 C 4.960938 14.445312 7.4375 15.140625 9.769531 14.53125 C 12.101562 13.921875 13.921875 12.101562 14.53125 9.769531 C 15.140625 7.4375 14.445312 4.960938 12.714844 3.285156 Z M 11.773438 11.773438 C 9.976562 13.574219 7.15625 13.851562 5.039062 12.441406 C 2.921875 11.027344 2.097656 8.316406 3.070312 5.964844 C 4.042969 3.617188 6.539062 2.277344 9.035156 2.773438 C 11.53125 3.265625 13.332031 5.457031 13.332031 8 C 13.339844 9.414062 12.777344 10.773438 11.773438 11.773438 Z M 11.773438 11.773438 "
      />

      <path
        *ngSwitchCase="'menu'"
        d="M2 5.333h12A.667.667 0 1 0 14 4H2a.667.667 0 0 0 0 1.333Zm12 5.334H2A.667.667 0 1 0 2 12h12a.666.666 0 1 0 0-1.333Zm0-3.334H2a.667.667 0 0 0 0 1.334h12a.667.667 0 1 0 0-1.334Z"
      />

      <path
        *ngSwitchCase="'overview'"
        fill-rule="evenodd"
        d="M 0,2.400002 V 13.599998 H 16 V 2.400002 Z M 14.400013,8.000023 H 11.199996 V 4.000002 h 3.200017 z M 9.6000089,6.400005 H 6.3999911 V 4.000002 H 9.6000089 Z M 6.3999911,8.000023 h 3.2000178 v 3.999988 H 6.3999911 Z M 1.6,4.000002 h 3.2000045 v 8.000009 H 1.6 Z m 9.599996,8.000009 V 9.600009 h 3.200017 v 2.400002 z"
      />

      <path
        *ngSwitchCase="'positions'"
        fill-rule="evenodd"
        d="m 1.4545429,9.4545307 0.3636387,0.0509 3.323639,-3.3236144 c -0.130909,-0.472726 -0.00727,-1.010906 0.378195,-1.389089 0.5672723,-0.574546 1.4836351,-0.574546 2.0509074,0 0.3854204,0.378183 0.5090634,0.916363 0.3781544,1.389089 l 1.8690961,1.8690844 0.3636225,-0.0509 c 0.130909,0 0.254552,0 0.363663,0.0509 L 13.1418,5.4545483 c -0.0509,-0.109091 -0.0509,-0.232729 -0.0509,-0.363638 0,-0.38577 0.153235,-0.755737 0.426012,-1.028519 0.272778,-0.272778 0.642774,-0.426024 1.028519,-0.426024 0.385785,0 0.75574,0.153246 1.028518,0.426024 C 15.846725,4.3351733 16,4.7051403 16,5.0909103 c 0,0.385769 -0.153275,0.75574 -0.426053,1.028518 -0.272778,0.2727824 -0.642733,0.4260284 -1.028518,0.4260284 -0.130909,0 -0.254512,0 -0.363623,-0.05091 l -2.596341,2.596361 c 0.0509,0.109071 0.0509,0.232714 0.0509,0.363623 0,0.385786 -0.153275,0.7557413 -0.426053,1.0285183 -0.272777,0.272778 -0.642732,0.426053 -1.028518,0.426053 -0.3857451,0 -0.7557405,-0.153275 -1.0285183,-0.426053 C 8.8804999,10.210272 8.7272653,9.8403167 8.7272653,9.4545307 l 0.050902,-0.363623 -1.8690961,-1.869087 c -0.2327135,0.05091 -0.4945315,0.05091 -0.727245,0 l -3.3236471,3.3236183 0.05091,0.363663 c 0,0.385745 -0.1532467,0.755741 -0.4260286,1.028519 -0.272777,0.272777 -0.6427481,0.426012 -1.0285176,0.426012 -0.3857695,0 -0.75573656,-0.153235 -1.0285184,-0.426012 C 0.15324631,11.664843 0,11.294847 0,10.909102 0,10.523317 0.15324631,10.153362 0.4260245,9.8805837 0.69880634,9.6078057 1.0687734,9.4545307 1.4545429,9.4545307 Z"
      />

      <path
        *ngSwitchCase="'activity'"
        d="M 16,14.400013 H 0 V 0 H 1.6 V 12.800026 H 3.2000045 V 5.60002 h 3.1999866 v 7.200006 H 8.0000223 V 2.400002 h 3.1999737 v 10.400024 h 1.600031 V 8.799993 H 16 Z"
        fill-rule="evenodd"
      />

      <path
        *ngSwitchCase="'documents'"
        d="M 9.33335,0 H 3.9999999 C 3.266665,0 2.666665,0.6 2.666665,1.3333353 V 12 c 0,0.73335 0.6,1.33335 1.3333349,1.33335 H 12 c 0.73335,0 1.33335,-0.6 1.33335,-1.33335 V 4 Z M 12,12 H 3.9999999 V 1.3333353 H 8.66665 V 4.666664 H 12 Z M 1.333335,2.666665 V 14.66665 H 12 V 16 H 1.333335 C 0.6,16 0,15.4 0,14.66665 V 2.666665 Z M 5.3333499,6.66665 V 8 H 10.66665 V 6.66665 Z m 0,2.6667 v 1.3333 H 8.66665 v -1.3333 z"
        fill-rule="evenodd"
      />

      <path *ngSwitchCase="'plus'" d="M12 9H7v5H5V9H0V7h5V2h2v5h5z" />

      <path
        *ngSwitchCase="'x'"
        d="M10.2639 8L16 13.7361L13.7361 16L8 10.2639L2.26386 16L0 13.7361L5.73614 8L0 2.26386L2.26386 0L8 5.73614L13.7361 0L16 2.26386L10.2639 8Z"
      />

      <g *ngSwitchCase="'exit'">
        <path
          d="M 9.199219 6.398438 C 9.421875 6.398438 9.601562 6.222656 9.601562 6 L 9.601562 2.800781 C 9.601562 2.136719 9.0625 1.601562 8.398438 1.601562 L 1.199219 1.601562 C 0.539062 1.601562 0 2.136719 0 2.800781 L 0 12.398438 C 0 12.996094 0.429688 13.648438 0.976562 13.882812 L 5.105469 15.652344 C 5.25 15.710938 5.394531 15.742188 5.53125 15.742188 C 5.703125 15.742188 5.867188 15.695312 6.003906 15.605469 C 6.253906 15.441406 6.398438 15.144531 6.398438 14.800781 L 6.398438 13.597656 L 8.398438 13.597656 C 9.0625 13.597656 9.601562 13.0625 9.601562 12.398438 L 9.601562 9.199219 C 9.601562 8.976562 9.421875 8.800781 9.199219 8.800781 C 8.980469 8.800781 8.800781 8.976562 8.800781 9.199219 L 8.800781 12.398438 C 8.800781 12.621094 8.621094 12.800781 8.398438 12.800781 L 6.398438 12.800781 L 6.398438 5.199219 C 6.398438 4.601562 5.96875 3.953125 5.421875 3.71875 L 2.347656 2.398438 L 8.398438 2.398438 C 8.621094 2.398438 8.800781 2.578125 8.800781 2.800781 L 8.800781 6 C 8.800781 6.21875 8.980469 6.398438 9.199219 6.398438 Z M 5.105469 4.453125 C 5.363281 4.5625 5.601562 4.921875 5.601562 5.199219 L 5.601562 14.800781 C 5.601562 14.882812 5.578125 14.929688 5.5625 14.9375 C 5.550781 14.945312 5.5 14.949219 5.421875 14.917969 L 1.292969 13.148438 C 1.035156 13.035156 0.800781 12.679688 0.800781 12.398438 L 0.800781 2.800781 C 0.800781 2.738281 0.8125 2.675781 0.839844 2.625 Z M 5.105469 4.453125 "
        />
        <path
          d="M 14.683594 7.316406 L 12.28125 4.917969 C 12.128906 4.761719 11.875 4.761719 11.71875 4.917969 C 11.5625 5.074219 11.5625 5.328125 11.71875 5.480469 L 13.433594 7.199219 L 8.398438 7.199219 C 8.179688 7.199219 8 7.378906 8 7.597656 C 8 7.820312 8.179688 8 8.398438 8 L 13.433594 8 L 11.71875 9.714844 C 11.5625 9.871094 11.5625 10.125 11.71875 10.28125 C 11.796875 10.359375 11.898438 10.398438 12 10.398438 C 12.101562 10.398438 12.207031 10.359375 12.285156 10.28125 L 14.683594 7.882812 C 14.839844 7.726562 14.839844 7.472656 14.683594 7.316406 Z M 14.683594 7.316406 "
        />
      </g>

      <g *ngSwitchCase="'printer'">
        <path
          d="M 16 6.464844 C 16 5.527344 15.21875 4.765625 14.261719 4.765625 L 13.21875 4.765625 L 13.21875 2.382812 L 12.175781 2.382812 L 12.175781 0 L 3.824219 0 L 3.824219 2.382812 L 2.78125 2.382812 L 2.78125 4.765625 L 1.738281 4.765625 C 0.78125 4.765625 0 5.527344 0 6.464844 L 0 13.617188 L 3.824219 13.617188 L 3.824219 16 L 12.175781 16 L 12.175781 13.617188 L 16 13.617188 Z M 12.523438 3.0625 L 12.523438 4.765625 L 12.175781 4.765625 L 12.175781 3.0625 Z M 4.523438 0.679688 L 11.476562 0.679688 L 11.476562 4.765625 L 4.523438 4.765625 Z M 3.476562 3.0625 L 3.824219 3.0625 L 3.824219 4.765625 L 3.476562 4.765625 Z M 11.476562 15.320312 L 4.523438 15.320312 L 4.523438 11.234375 L 11.476562 11.234375 Z M 15.304688 12.9375 L 12.175781 12.9375 L 12.175781 11.234375 L 13.566406 11.234375 L 13.566406 10.554688 L 2.433594 10.554688 L 2.433594 11.234375 L 3.824219 11.234375 L 3.824219 12.9375 L 0.695312 12.9375 L 0.695312 6.464844 C 0.695312 5.902344 1.164062 5.445312 1.738281 5.445312 L 14.261719 5.445312 C 14.835938 5.445312 15.304688 5.902344 15.304688 6.464844 Z M 15.304688 12.9375 "
        />
        <path
          d="M 12.871094 6.46875 C 12.292969 6.46875 11.824219 6.925781 11.824219 7.488281 C 11.824219 8.050781 12.292969 8.511719 12.871094 8.511719 C 13.445312 8.511719 13.914062 8.050781 13.914062 7.488281 C 13.914062 6.925781 13.445312 6.46875 12.871094 6.46875 Z M 12.871094 7.828125 C 12.675781 7.828125 12.523438 7.675781 12.523438 7.488281 C 12.523438 7.300781 12.675781 7.148438 12.871094 7.148438 C 13.0625 7.148438 13.21875 7.300781 13.21875 7.488281 C 13.21875 7.675781 13.0625 7.828125 12.871094 7.828125 Z M 12.871094 7.828125 "
        />
      </g>

      <g *ngSwitchCase="'android'">
        <path
          d="M7.65503 8.33814C7.65503 8.58527 7.45568 8.78582 7.20808 8.78582H2.03142C1.78429 8.78582 1.58423 8.58529 1.58423 8.33814V3.71322C2.94117 3.70826 6.10677 3.70826 7.65503 3.71322V8.33814Z"
          fill="#374761"
        />
        <path
          d="M3.50192 1.34198C3.52746 1.39212 3.50806 1.45479 3.45723 1.4808C3.40686 1.50634 3.34421 1.48625 3.31867 1.43588L2.66432 0.160525C2.63831 0.109695 2.6591 0.0481949 2.70949 0.0219599C2.75986 -0.00406732 2.82205 0.0160478 2.84805 0.0666703L3.50194 1.34203L3.50192 1.34198Z"
          fill="#374761"
        />
        <path
          d="M5.8852 1.39567C5.85681 1.44509 5.79393 1.46212 5.74425 1.43281C5.69506 1.40489 5.67827 1.342 5.70712 1.29256L6.42366 0.0517054C6.45204 0.00251476 6.51541 -0.0149676 6.56412 0.0141081C6.61379 0.0422368 6.63056 0.105399 6.6022 0.154336L5.88517 1.39565L5.8852 1.39567Z"
          fill="#374761"
        />
        <path
          d="M9.23547 7.08405C9.23547 7.44375 8.94414 7.7358 8.58373 7.7358C8.22332 7.7358 7.93127 7.44398 7.93127 7.08405V4.2318C7.93127 3.87139 8.22309 3.58029 8.58373 3.58029C8.94391 3.58029 9.23547 3.87142 9.23547 4.2318V7.08405Z"
          fill="#374761"
        />
        <path
          d="M1.30374 7.08405C1.30374 7.44375 1.01146 7.7358 0.652227 7.7358C0.291588 7.7358 0 7.44398 0 7.08405V4.2318C0 3.87139 0.291334 3.58029 0.652227 3.58029C1.01146 3.58029 1.30374 3.87142 1.30374 4.2318V7.08405Z"
          fill="#374761"
        />
        <path
          d="M4.14203 10.3483C4.14203 10.7079 3.85021 11 3.48934 11C3.12965 11 2.83832 10.7082 2.83832 10.3483V7.49601C2.83832 7.13655 3.12965 6.84378 3.48934 6.84378C3.84998 6.84378 4.14203 7.13655 4.14203 7.49601V10.3483Z"
          fill="#374761"
        />
        <path
          d="M6.40308 10.3483C6.40308 10.7079 6.11126 11 5.75062 11C5.39093 11 5.09888 10.7082 5.09888 10.3483V7.49601C5.09888 7.13655 5.39069 6.84378 5.75062 6.84378C6.11103 6.84378 6.40308 7.13655 6.40308 7.49601V10.3483Z"
          fill="#374761"
        />
        <path
          d="M4.61975 0.665619C3.03435 0.665619 1.72872 1.87218 1.5542 3.42304H7.68507C7.51103 1.87218 6.20609 0.665642 4.61975 0.665642V0.665619ZM3.23654 2.33734C3.09511 2.33734 2.98089 2.2236 2.98089 2.08266C2.98089 1.9422 3.09511 1.82844 3.23654 1.82844C3.37654 1.82844 3.49076 1.94217 3.49076 2.08266C3.49076 2.2236 3.37654 2.33734 3.23654 2.33734ZM6.00838 2.33734C5.86744 2.33734 5.75321 2.2236 5.75321 2.08266C5.75321 1.9422 5.86744 1.82844 6.00838 1.82844C6.14979 1.82844 6.26309 1.94217 6.26309 2.08266C6.26309 2.2236 6.14981 2.33734 6.00838 2.33734Z"
          fill="#374761"
        />
      </g>

      <path
        *ngSwitchCase="'download'"
        d="M0 11H10V9.70588H0V11ZM10 3.88235H7.14286V0H2.85714V3.88235H0L5 8.41177L10 3.88235Z"
        fill="white"
      />

      <path
        *ngSwitchCase="'apple'"
        d="M9.14949 9.62436C8.63795 10.3063 8.0956 10.9718 7.26975 10.9828C6.44389 10.9993 6.17888 10.5483 5.24208 10.5483C4.29913 10.5483 4.00946 10.9718 3.22675 10.9993C2.41938 11.0268 1.80923 10.2733 1.29153 9.60786C0.237643 8.24945 -0.569724 5.74712 0.514983 4.06423C1.05117 3.22829 2.01262 2.70032 3.05418 2.68382C3.84306 2.67282 4.59496 3.16229 5.08184 3.16229C5.56257 3.16229 6.4747 2.57383 7.42999 2.66182C7.83059 2.67832 8.95227 2.80481 9.67336 3.75075C9.61789 3.78375 8.33596 4.4547 8.34829 5.84611C8.36678 7.507 9.98151 8.06246 10 8.06796C9.98151 8.10646 9.74115 8.85991 9.14949 9.62436ZM5.63036 0.824945C6.08027 0.368475 6.826 0.0219985 7.44231 0C7.52243 0.643457 7.23277 1.29241 6.80135 1.75438C6.37609 2.22185 5.6735 2.58483 4.98323 2.53533C4.89079 1.90287 5.23592 1.24292 5.63036 0.824945Z"
        fill="#374761"
      />

      <svg
        *ngSwitchCase="'image'"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background:new 0 0 512 512;"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              d="M184.765,86.883c-27.619,0-50.087,22.468-50.087,50.087s22.468,50.087,50.087,50.087
              c27.619,0,50.087-22.468,50.087-50.087C234.852,109.351,212.384,86.883,184.765,86.883z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M461.913,0H50.087C22.468,0,0,22.468,0,50.087v411.826C0,489.532,22.468,512,50.087,512h411.826
              C489.532,512,512,489.532,512,461.913V50.087C512,22.468,489.532,0,461.913,0z M445.217,293.609l-88.368-88.37
              c-6.521-6.52-17.091-6.52-23.613,0L228.174,310.304l-60.544-54.979c-6.52-6.52-17.089-6.52-23.609,0L66.783,327V83.478
              c0-9.228,7.467-16.696,16.696-16.696h345.043c9.228,0,16.696,7.467,16.696,16.696V293.609z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </svg>
  </div>
</ng-container>
