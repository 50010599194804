// Super simple. If needs more complexity, use an npm module.
export function pluralize(word: string, count?: number, prependCount?: boolean): string {
  const isPlural = count !== 1
  const isEndingInXS = word.match(/[xs]$/i) != null
  const isEndingInY = word.match(/y$/i) != null
  let outputWord = word

  if (isPlural) {
    if (isEndingInXS) outputWord = word + 'es'
    else if (isEndingInY) outputWord = word.replace(/y$/i, 'ies')
    else outputWord = word + 's'
  }

  return prependCount ? `${count} ${outputWord}` : outputWord
}
