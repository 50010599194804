import { AbstractControl, ValidationErrors } from '@angular/forms'

// Very simple validator—just checks that a string contains 10 or more digits.
// This is necessary for Authy. We strip non-digit characters before sending the phone number
// to Authy—we let them do the ultimate validation on what is or isn't a phone number.

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value
  if (!value || value.replace(/\D/g, '').length > 9) return null
  return { phone: true }
}
