import { Pipe, PipeTransform } from '@angular/core'
import { abbreviateCurrency } from '../functions'

@Pipe({ name: 'abbreviateCurrency' })
export class AbbreviateCurrencyPipe implements PipeTransform {
  transform(value: any) {
    if (value == null) return value
    return abbreviateCurrency(value, 2)
  }
}
