import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, NEVER } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators'
import { Subject, Observable } from 'rxjs'

import { Workgroup } from '../../shared/models'
import { UserSessionService } from './user-session.service'

@UntilDestroy()
@Injectable()
export class WorkgroupService implements OnDestroy {
  private currentWorkgroupCodeSubject = new BehaviorSubject<string>(this.lastWorkgroupCode)
  private checkWorkgroupStatus = new Subject<{ status: boolean; message: string }>()

  currentWorkgroupCode$ = this.currentWorkgroupCodeSubject.asObservable().pipe(
    filter(code => !!code),
    distinctUntilChanged(),
    shareReplay(1)
  )

  currentWorkgroup$ = this.currentWorkgroupCode$.pipe(
    switchMap(code => {
      return this.http.get<api.Workgroup>(`/api/v1/workgroup/${code}.json`).pipe(
        catchError(error => {
          this.checkError(error)
          return NEVER
        }),
        tap(() => (this.lastWorkgroupCode = code))
      )
    }),
    map(workgroup => new Workgroup().deserialize(workgroup)),
    shareReplay(1)
  )

  constructor(private http: HttpClient, private userSession: UserSessionService) {
    userSession.currentSession$
      .pipe(
        untilDestroyed(this),
        filter(session => !!session),
        map(session => session.workgroup_code)
      )
      .subscribe(code => this.setWorkgroupCode(code))
  }

  ngOnDestroy(): void {}

  getWorkgroupStatus(): Observable<{ status: boolean; message: string }> {
    return this.checkWorkgroupStatus.asObservable()
  }
  setWorkgroupCode(code: string): void {
    this.currentWorkgroupCodeSubject.next(code)
  }

  private get lastWorkgroupCode(): string | null {
    return localStorage.getItem('workgroup_code')
  }
  private set lastWorkgroupCode(code: string) {
    localStorage.setItem('workgroup_code', code)
  }
  getWorkgroupCode(): string | null {
    return localStorage.getItem('workgroup_code')
  }

  private checkError(err) {
    console.log(err)
    if (err.status === 401) {
      this.checkWorkgroupStatus.next({ status: false, message: err.error.message })
    }
  }
}
