import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { ConsoleLogger, GlobalErrorHandler, TitleService } from '../shared/services'
import { SharedModule } from '../shared/'
import { PageNotFoundComponent, WorkgroupThemeComponent } from './components'
import { throwIfAlreadyLoaded } from './module-import-guard'
import {
  AuthGuard,
  CacheService,
  NoRestrictionGuard,
  OtpService,
  UserSessionService,
  WorkgroupService
} from './services'
import { SurvicateComponent } from './components/survicate/survicate.component'

@NgModule({
  imports: [BrowserAnimationsModule, SharedModule],
  declarations: [PageNotFoundComponent, WorkgroupThemeComponent, SurvicateComponent],
  exports: [PageNotFoundComponent, WorkgroupThemeComponent, SurvicateComponent],
  providers: [
    GlobalErrorHandler,
    { provide: ErrorHandler, useExisting: GlobalErrorHandler }, // Work around a DI typing issue
    { provide: 'WINDOW', useFactory: () => window },
    AuthGuard,
    CacheService,
    ConsoleLogger,
    NoRestrictionGuard,
    OtpService,
    TitleService,
    UserSessionService,
    WorkgroupService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule')
  }
}
