export const environment: AppEnv = {
  name: 'staging',
  isProduction: true,
  logLevel: 'INFO',
  logToHoneybadger: true,
  debug: {
    angular: true,
    angularRouting: false,
    errorReporter: true,
    styles: true
  },
  quoteFeature: true,
  quoteUrls1: 'https://unio-atria.markitqa.com/js/app.js',
  quoteUrls2: 'https://unio-atria.markitqa.com/js/globals.js',
  quoteUrls3: 'https://unio-atria.markitqa.com/js/chunk-vendors.js',
  quoteUrls4: 'https://unio-atria.markitqa.com/css/app.css',
  analitycsId: 'G-W0RT82J3KX',
  cmsDomainConsolidated: 'unio-resources-staging.cusonet.com'
}
