const { abs, floor, round } = Math
const CENT = 0.005
const TEN = 10
const HUNDRED = 100
const THOUSAND = 1000
const MILLION = 1000000
const BILLION = 1000000000
const TRILLION = 1000000000000

function truncate(number: number, base: number, power: number) {
  const divided = number / base
  // return divided < TEN
  //  ? round(divided * 100) / 10
  //  :
  const powerFactor = Math.pow(10, power)
  return round(divided * powerFactor) / powerFactor
}

export function abbreviateCurrency(number: number, power: number): string {
  if (typeof number !== 'number') {
    const parsed = parseFloat(number)
    if (isNaN(parsed)) throw Error(`Expected input "${number}" to be a number`)
    number = parsed
  }
  number = Math.round(number * 100) / 100
  const isNegative = number < 0
  const absoluteValue = abs(number)
  let abbreviated: string

  if (absoluteValue < CENT) return '$0'
  else if (absoluteValue < TEN) abbreviated = absoluteValue.toFixed(2)
  else if (absoluteValue < THOUSAND) abbreviated = absoluteValue.toFixed(2)
  else if (absoluteValue < MILLION) abbreviated = truncate(absoluteValue, THOUSAND, power) + 'k'
  else if (absoluteValue < BILLION) abbreviated = truncate(absoluteValue, MILLION, power) + 'm'
  else if (absoluteValue < TRILLION) abbreviated = truncate(absoluteValue, BILLION, power) + 'b'
  else abbreviated = truncate(absoluteValue, TRILLION, power) + 't'

  return isNegative ? '-$' + abbreviated : '$' + abbreviated
}
