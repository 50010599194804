import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WindowResizeService {
  constructor() {}

  private currentSizeSubject = new Subject<number | string>()
  readonly currentSize$ = this.currentSizeSubject.asObservable()

  setSize(size: number | string): void {
    this.currentSizeSubject.next(size)
  }
}
