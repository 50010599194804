import {
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup
} from '@angular/forms'
import mapValues from 'lodash-es/mapValues'
import has from 'lodash-es/has'

const SPECIAL_CHAR_VALIDATOR = new RegExp(/[!@#\$%\^\&*\)\(+=._-]/)
const NUMBER_VALIDATOR = new RegExp(/\d/)
const UPPERCASE_VALIDATOR = new RegExp(/[A-Z]/)
const LOWERCASE_VALIDATOR = new RegExp(/[a-z]/)

const errorPasswordValidations: api.PasswordValidations = {
  minlength: true,
  missSpecialChar: true,
  missNumber: true,
  missUppercase: true,
  missLowercase: true
}

export const passwordValidators = [
  Validators.required,
  Validators.minLength(10),
  Validators.maxLength(60),
  regexValidator(SPECIAL_CHAR_VALIDATOR, { missSpecialChar: true }),
  regexValidator(NUMBER_VALIDATOR, { missNumber: true }),
  regexValidator(UPPERCASE_VALIDATOR, { missUppercase: true }),
  regexValidator(LOWERCASE_VALIDATOR, { missLowercase: true })
]

export const modernUsernameValidator = Validators.compose([
  Validators.minLength(4),
  Validators.pattern(/^[^\s:@]+$/)
])

export const modernPasswordValidator = Validators.compose([
  Validators.minLength(10),
  Validators.maxLength(60),
  Validators.pattern(/^\S*$/), // No spaces
  Validators.pattern(/\d/), // At least one number
  Validators.pattern(/[!@#\$%\^\&*\)\(+=._-]/), // At least one special char
  Validators.pattern(/[A-Z]/i), // At least one letter
  Validators.pattern(/[a-z]/i) // At least one letter
])

export function validatePasswordErrors(form: FormGroup, field: string): api.PasswordValidations {
  let errors = errorPasswordValidations
  const passValueErrors = form.get(field).errors
  if (passValueErrors) {
    mapValues(errors, (value, key) => {
      errors[key] = has(passValueErrors, key)
    })
  } else {
    // this means no errors, put all values in false
    mapValues(errors, (value, key) => {
      errors[key] = false
    })
  }
  // required error has to display minlength error
  if (has(passValueErrors, 'required')) {
    errors.minlength = true
  }
  return errors
}

function regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return control && control.value && regex.test(control.value) ? null : error
  }
}
