import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router'
import { filter } from 'rxjs/operators'

@Injectable()
export class TitleService {
  appName = 'App'
  private previousTitleRoute: Route

  constructor(router: Router, route: ActivatedRoute, private pageTitle: Title) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      const { root } = route
      let { children } = root
      let title
      let titleRoute

      while (children.length) {
        const primaryChild = children.find(child => child.outlet === 'primary')
        if (primaryChild) {
          const {
            snapshot: { routeConfig }
          } = primaryChild
          const { data = {} } = routeConfig
          if (data.title) {
            title = data.title
            titleRoute = routeConfig
          }
          children = primaryChild.children
        } else break
      }

      // Only set the title if the route has changed, or if none exists (for default).
      if (titleRoute == null || titleRoute !== this.previousTitleRoute) {
        this.previousTitleRoute = titleRoute
        this.set(title)
      }
    })
  }

  set(title?: string): void {
    title = title != null ? `${title} – ${this.appName}` : this.appName
    this.pageTitle.setTitle(title)
  }
}
