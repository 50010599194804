import { catchError, filter, switchMap, take } from 'rxjs/operators'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpClient
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

import { environment } from '../../../environments/environment'

@Injectable()
export class WordpressInterceptor implements HttpInterceptor {
  token = new BehaviorSubject<any>(null)
  fetchingToken: boolean = false
  needsRefresh: boolean = true

  constructor(private http: HttpClient) {
    this.restoreTokens()
  }

  private restoreTokens() {
    const token = JSON.parse(localStorage.getItem(`wptoken`))
    if (token) {
      this.token.next(token)
      this.fetchingToken = false
      this.needsRefresh = false
    }
  }

  private persistTokens() {
    this.token.pipe(take(1)).subscribe(token => {
      if (token) {
        // Strip out fields like message, user_nicename, etc.
        const savedToken = {
          token: token.token
        }
        localStorage.setItem(`wptoken`, JSON.stringify(savedToken))
      }
    })
  }

  private fetchToken() {
    if (this.fetchingToken || !this.needsRefresh) {
      return
    }
    this.fetchingToken = true
    this.http
      .get(`/api/v1/get_token.json`)
      .pipe(take(1))
      .subscribe(
        authData => {
          ;(<BehaviorSubject<any>>this.token).next(authData)
          this.persistTokens()
          this.fetchingToken = false
          this.needsRefresh = false
        },
        () => {
          this.fetchingToken = false
        }
      )
  }

  private getToken(): Observable<any> {
    this.fetchToken()
    return (<BehaviorSubject<any>>this.token).pipe(
      filter(val => val !== null),
      take(1)
    )
  }

  private setAuthToken(req: HttpRequest<any>, token: string) {
    const headers = req.headers
    headers.append('Authorization', `Bearer ${token}`)
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      (!/.*resources\.cusonet.*/.test(req.url) && !/.*unio\-resources.*/.test(req.url)) ||
      /.*api\/jwt-auth\/v1\/token.*/.test(req.url)
    ) {
      return next.handle(req)
    }

    return this.getToken().pipe(
      switchMap(authData => {
        return next.handle(this.setAuthToken(req, authData.token)).pipe(
          catchError(error => {
            if (error.status !== 401 && error.status !== 403) {
              return Observable.throw(error)
            }
            ;(<BehaviorSubject<any>>this.token).next(null)
            this.needsRefresh = true
            return this.getToken().pipe(
              switchMap(newAuthData => {
                return next.handle(this.setAuthToken(req, newAuthData.token))
              })
            )
          })
        )
      })
    )
  }
}
