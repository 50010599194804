import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { environment } from '../environments/environment'
import { PageNotFoundComponent } from './core/components'
import { AuthGuard, NoRestrictionGuard } from './core/services'

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'accounts',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    redirectTo: '/login/purge_session',
    pathMatch: 'full'
  },
  {
    path: 'login',
    //loadChildren: './auth/auth.module#AuthModule',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard, NoRestrictionGuard],
    canActivateChild: [AuthGuard, NoRestrictionGuard],
    //loadChildren: './account/account.module#AccountModule'
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Page Not Found' }
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      enableTracing: environment.debug.angularRouting
    })
  ]
})
export class AppRoutingModule {}
