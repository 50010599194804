import { Deserializable } from './deserializable.model'

export enum OtpMethod {
  Email = 'email',
  SMS = 'sms',
  Authy = 'authy',
  Authenticator = 'app'
}

export interface OtpConfig {
  available_otp_methods: OtpMethod[]
  last_used_method: OtpMethod
}

export class OtpConfiguration implements Deserializable {
  private available_otp_methods: OtpMethod[] = []
  private last_used_method: OtpMethod

  get methods(): OtpMethod[] {
    return this.available_otp_methods
  }

  get hasAuthenticator(): boolean {
    // TODO: https://atriawealth.atlassian.net/browse/MPV-153
    // this is temporary until we have device authentication
    // return this.methods.indexOf(OtpMethod.Authenticator) > -1
    return false
  }

  get hasAuthy(): boolean {
    return this.methods.indexOf(OtpMethod.Authy) > -1
  }

  get hasSMS(): boolean {
    return this.methods.indexOf(OtpMethod.SMS) > -1
  }

  deserialize(input: OtpConfig): this {
    console.log(input)
    Object.assign(this, input)
    return this
  }
}
