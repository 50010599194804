import { Component } from '@angular/core'

@Component({
  selector: 'mpv-page-not-found-page',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
  goBack () {
    window.history.back()
    return false
  }
}
