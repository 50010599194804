import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { WorkgroupService } from '../services'

@UntilDestroy()
@Component({
  selector: 'mpv-workgroup-theme',
  template: `
    <div [innerHTML]="styles"></div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class WorkgroupThemeComponent implements OnInit, OnDestroy {
  styles: SafeHtml

  private readonly HEX_COLOR = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  private readonly stylesTemplate = `
    <style type="text/css">
      .workgroup-primary-background-color,
      .railnav-accounts .is-active,
      .railnav-accounts .is-expanded {
        background-color: PRIMARY_COLOR !important;
      }
      .workgroup-primary-border-color {
        border-color: PRIMARY_COLOR !important;
      }
      .workgroup-secondary-border-color {
        border-color: SECONDARY_COLOR !important;
      }
      .bg-primary, .btn-primary.btn-theme {
        background-color: PRIMARY_COLOR !important;
      }
      .btn-primary.btn-theme, .btn-secondary.btn-theme {
        border: 1px solid PRIMARY_COLOR !important;
      }
      .btn-secondary.btn-theme, .btn-primary.btn-theme:hover {
        background-color: #fff !important;
        color: PRIMARY_COLOR !important;
      }
      .btn-secondary.btn-theme:hover {
        background-color: PRIMARY_COLOR !important;
        color: #fff !important;
      }
      .p-carousel-container .p-link {
        color: PRIMARY_COLOR !important;
      }
      .plating-document:before {
        background-color: SECONDARY_COLOR !important;
      }
      .workgroup-modal .p-dialog-header {
        background-color: PRIMARY_COLOR !important;
      }
    </style>
  `

  constructor(private sanitizer: DomSanitizer, private workgroup: WorkgroupService) {}

  ngOnInit(): void {
    this.renderStyles()
    this.workgroup.currentWorkgroup$
      .pipe(untilDestroyed(this))
      .subscribe(workgroup => this.renderStyles(workgroup.primary_color, workgroup.secondary_color))
  }

  ngOnDestroy(): void {}

  private renderStyles(primary: string = '#444', secondary: string = '#bbb'): void {
    const styles = this.stylesTemplate
      .replace(/PRIMARY_COLOR/g, this.sanitizeHexColor(primary))
      .replace(/SECONDARY_COLOR/g, this.sanitizeHexColor(secondary))

    this.styles = this.sanitizer.bypassSecurityTrustHtml(styles)
  }

  private sanitizeHexColor(color: string): string {
    const isValid = this.HEX_COLOR.test('' + color)
    return isValid ? color : ''
  }
}
