import { Injectable } from '@angular/core'
import { Session } from '../models'
import { BehaviorSubject } from 'rxjs'

const EXCLUDED_WORKGROUPS = ['WFS', 'RW']

@Injectable({
  providedIn: 'root'
})
export class QrModalService {
  showQRModal = false
  isShownOnLogin = false
  private isQrModalHiddenSubject = new BehaviorSubject<boolean>(false)
  readonly isQrModalHidden$ = this.isQrModalHiddenSubject.asObservable()
  readonly excludedWorkgroups = EXCLUDED_WORKGROUPS

  constructor() {}

  isMobileApp() {
    return !!window['ReactNativeWebView']
  }

  isEnabledDownloadForWorkgroup(workgroupCode: string) {
    return !this.excludedWorkgroups.includes(workgroupCode.toUpperCase())
  }

  show(isShownOnLogin: boolean = false) {
    this.isShownOnLogin = isShownOnLogin
    this.showQRModal = true
    this.isQrModalHiddenSubject.next(false)
  }

  hide() {
    this.showQRModal = false
    if (this.isShownOnLogin) {
      this.isQrModalHiddenSubject.next(true)
      this.isShownOnLogin = false
    }
  }

  isEnableToShowOnLogin(session: Session) {
    return !this.isMobileApp() && this.isEnabledDownloadForWorkgroup(session.workgroup_code)
  }
}
