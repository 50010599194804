import { HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { cloneDeep } from 'lodash-es'

interface CacheOptions {
  matchers: RegExp[]
  limit?: number
  method?: 'memory' | 'sessionStorage' | 'localStorage'
  deepCopy?: boolean
  alwaysRefresh?: boolean
}

type Caches = Map<string, Cache>

class Cache {
  static defaultOptions: Partial<CacheOptions> = {
    limit: 10,
    method: 'sessionStorage',
    deepCopy: false,
    alwaysRefresh: true
  }
  readonly config: CacheOptions
  private storageRef: Storage | null
  private cache: Map<string, any>

  constructor (readonly id: string, options: CacheOptions) {
    this.config = Object.freeze(Object.assign({}, Cache.defaultOptions, options))

    switch (this.config.method) {
      case 'sessionStorage':
        this.storageRef = sessionStorage
        break
      case 'localStorage':
        this.storageRef = localStorage
        break
    }

    this.cache = new Map()
    this.readFromStorage()
  }

  get (key: string): any {
    const { cache } = this
    const isCached = cache.has(key)
    const value = cache.get(key)

    if (isCached) {
      // Place the key at the end of the set for least-recently-used limit enforcement
      cache.delete(key)
      cache.set(key, value)
      this.writeToStorage()
    }

    return this.config.deepCopy
      ? cloneDeep(value)
      : value
  }

  set (key: string, value: any): this {
    if (this.config.deepCopy) value = cloneDeep(value)

    this.cache.delete(key)
    this.cache.set(key, value)
    this.pruneCacheToLimit()
    this.writeToStorage()
    return this
  }

  clear (): this {
    this.cache.clear()
    this.writeToStorage()
    return this
  }

  private pruneCacheToLimit () {
    const { cache, config: { limit } } = this

    let excess = cache.size - limit
    if (excess > 0) {
      const keys = cache.keys()
      while (excess--) cache.delete(keys.next().value)
    }
  }

  private readFromStorage () {
    if (!this.storageRef) return

    this.cache.clear()
    const storageData = this.storageRef.getItem(`Cache[${ this.id }]`)
    if (storageData) {
      this.cache = new Map(JSON.parse(storageData))
    }
  }

  private writeToStorage () {
    if (!this.storageRef) return

    const cacheKey = `Cache[${ this.id }]`
    const keyValuePairs = Array.from(this.cache)

    if (keyValuePairs.length) this.storageRef.setItem(cacheKey, JSON.stringify(keyValuePairs))
    else this.storageRef.removeItem(cacheKey)
  }
}

@Injectable()
export class CacheService {
  private caches: Caches = new Map()

  createCache (id: string, options: CacheOptions) {
    const cache = new Cache(id, options)
    this.caches.set(id, cache)
  }

  getRequestCache (req: HttpRequest<any>): Cache | undefined {
    const { method, url } = req
    if (method !== 'GET') return

    const caches = this.caches.values()
    let requestCache: Cache

    for (const cache of Array.from(caches)) {
      const matchers = cache.config.matchers
      const isMatch = !!matchers.find(pattern => !!pattern.exec(url))
      if (isMatch) {
        requestCache = cache
        break
      }
    }

    return requestCache
  }

  clearCaches () {
    this.caches.forEach(cache => cache.clear())
  }
}
