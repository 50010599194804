import { sample } from 'lodash-es'
import { OtpConfig } from '../../shared/models'
import { OtpAppSetup, OtpAuthySetup } from './otp.service'

export const mockGetOtp = {
  available_otp_methods: ['authy', 'email'],
  last_used_method: 'email',
  methods(): any[] {
    return this.available_otp_methods
  },
  hasAuthenticator(): boolean {
    return false
  },
  hasAuthy(): boolean {
    return true
  }
} as OtpConfig

export const mockGetSetupApp = {
  key: 'rkyyykx5jxcahyup',
  key_uri:
    'otpauth://totp/MyPortfolioView:email@example.com?secret=rkyyykx5jxcahyup&issuer=MyPortfolioView'
} as OtpAppSetup

export const mockPostSetupAuthy = {
  // delivery_channel: sample(['sms', 'app'])
  delivery_channel: sample(['authy', 'app'])
} as OtpAuthySetup
