import { AbstractControl } from '@angular/forms'

// Model for helping track the submission state of a form.
export interface FormStatusOptions {
  resetOnError: boolean | string
}

export class FormStatus {
  private static readonly defaultOptions = {
    resetOnError: false
  }

  private readonly config: FormStatusOptions

  private _isSubmitting = false
  private _error: string = null

  get isSubmitting(): boolean {
    return this._isSubmitting
  }

  get error(): string | null {
    return this._error
  }

  get isSubmittable(): boolean {
    const { form, isSubmitting } = this
    return form ? form.enabled && form.valid && !isSubmitting : !isSubmitting
  }
  get isUnsubmittable(): boolean {
    return !this.isSubmittable
  }

  constructor(private form?: AbstractControl, options?: Partial<FormStatusOptions>) {
    if (options) this.config = Object.assign({}, FormStatus.defaultOptions, options)
  }

  markAsSubmitting(): void {
    const { form } = this
    this._isSubmitting = true
    this.unsetError()
    if (form) form.disable()
  }

  markAsReady(): void {
    const { form } = this
    this._isSubmitting = false
    if (form) form.enable()
  }

  setError(message: string): void {
    const { form, config } = this
    this._error = message
    this.markAsReady()

    if (config && config.resetOnError) {
      if (config.resetOnError === true) form.reset()
      else form.get(config.resetOnError).reset()
    }
  }

  unsetError(): void {
    this._error = null
  }

  reset(): void {
    const { form } = this
    this.markAsReady()
    this.unsetError()
    if (form) {
      form.reset()
      form.enable()
    }
  }
}
