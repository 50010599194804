import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, Subject } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { OtpConfig, OtpConfiguration, OtpMethod } from '../../shared/models'
import { mockGetOtp, mockGetSetupApp, mockPostSetupAuthy } from './otp.service.mocks'

export enum OtpDeliveryChannel {
  App = 'app',
  Authy = 'authy'
}

export interface OtpAppSetup {
  key: string
  key_uri: string
}

export interface OtpAuthySetup {
  delivery_channel: OtpDeliveryChannel
}

@Injectable()
export class OtpService {
  private isMigrationIncomplete = new Subject<{ status: boolean; message: string }>()
  readonly configuration$: Observable<OtpConfiguration> = this.http
    .get<OtpConfig>(`/api/v1/otp.json`)
    .pipe(
      map(config => new OtpConfiguration().deserialize(config)),
      catchError(err => {
        if (err.error['code'] === 'migration_incomplete') {
          this.isMigrationIncomplete.next({ status: true, message: err.error['message'] })
        } else {
          this.isMigrationIncomplete.next({ status: false, message: '' })
        }
        // console.log('mockGetOtp : ' + mockGetOtp)
        return of(new OtpConfiguration().deserialize(mockGetOtp))
      }) // TODO: <- Remove after backend is in place.
    )

  constructor(private http: HttpClient) {}

  deliverCode(use_otp_method: OtpMethod): Observable<any> {
    // Authenticator doesn't require any action by the backend since the code is
    // generated on the device of the user.
    if (use_otp_method === OtpMethod.Authenticator) return of({})
    return this.http.post(`/api/v1/otp.json`, { use_otp_method }).pipe(catchError(() => of({}))) // TODO: <- Remove after backend is in place.
  }

  verifyCode(otp_method: OtpMethod, otp_code: string): Observable<any> {
    return this.http.put(`/api/v1/otp.json`, { otp_method, otp_code })
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || 'server error.')
  }
  setupApp(): Observable<OtpAppSetup> {
    return (
      this.http
        .get<OtpAppSetup>(`/api/v1/otp/setup_app.json`)
        // TODO: Remove after backend is in place.
        .pipe(catchError(() => of(mockGetSetupApp)))
    )
  }

  getMigrationStatus(): Observable<{ status: boolean; message: string }> {
    return this.isMigrationIncomplete.asObservable()
  }
  setupAuthy(mobilePhoneNumber: string): Observable<OtpAuthySetup> {
    // Strip any non-digit characters from the string.
    const cell = mobilePhoneNumber.replace(/\D/g, '')

    return (
      this.http
        .post<OtpAuthySetup>(`/api/v1/otp/setup_authy.json`, { cell })
        // TODO: Remove after backend is in place.
        .pipe(catchError(() => of(mockPostSetupAuthy)))
    )
  }
}
