import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import {
  BehaviorSubject,
  NEVER,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  timer
} from 'rxjs'
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  share,
  skip,
  switchMap,
  tap,
  throttleTime
} from 'rxjs/operators'
import { Session } from '../../shared/models'
import { environment } from '../../../environments/environment'
import ReactNativeService from '../../shared/services/react-native.service'

@Injectable()
export class UserSessionService implements OnDestroy {
  private readonly isInstantiated: boolean = false

  isExpiringSoon = true
  isDevelopment = environment.name === 'development'

  token_method = ''
  private refreshSessionThrottleDuration = 30000
  private expiryWarning = 60000 // How many ms before session expiry to warn users that their session will expire.

  private readonly currentSessionSubject = new BehaviorSubject<Session | null>(null)
  readonly currentSession$ = this.currentSessionSubject.asObservable().pipe(distinctUntilChanged())

  private readonly isAuthenticatedSubject = new ReplaySubject<boolean>(1)
  readonly isAuthenticated$ = this.isAuthenticatedSubject
    .asObservable()
    .pipe(distinctUntilChanged())

  readonly isUnrestricted$: Observable<boolean> = this.currentSession$.pipe(
    map(user => user && user.restrictions.hasNone),
    filter(value => value === true)
  )

  private readonly refreshSessionSubject = new Subject<null>()

  private subs: Subscription[] = []

  constructor(private http: HttpClient) {
    this.syncIsAuthenticatedWithCurrentSession()
    this.handleSessionExpiration()
    this.refreshSession()

    this.isInstantiated = true
  }

  updateSession(body: api.SessionData) {
    this.currentSessionSubject.next(new Session().deserialize(body))
  }

  // Populate local session data from server.
  // If error, assume session doesn't exist and destroy any existing local session data.
  populate(): Observable<HttpResponse<api.SessionData>> {
    return this.http.get<api.SessionData>('/api/v1/session.json', { observe: 'response' }).pipe(
      tap(
        ({ body }) => {
          this.currentSessionSubject.next(new Session().deserialize(body))
        },
        () => {
          const hadSession = this.currentSessionSubject.value != null
          this.destroy()
          if (hadSession) {
            alert('Your session has expired')
            const isDirectLogin = localStorage.getItem('is_direct_login')
            const mpvLogoutUrl = localStorage.getItem('mpv_logout_url')
            const workgroupCode = localStorage.getItem('workgroup_code')
            let loginPath: string

            if (mpvLogoutUrl) {
              loginPath = mpvLogoutUrl
            } else if (workgroupCode && isDirectLogin) {
              loginPath = 'https://mpv3.orcasnet.com/#/login/' + workgroupCode
            } else if (workgroupCode && !isDirectLogin) {
              loginPath = 'https://mpv.orcasnet.com/login/' + workgroupCode
            } else {
              loginPath = 'https://mpv.orcasnet.com'
            }
            localStorage.removeItem('workgroup_code')
            localStorage.removeItem('is_direct_login')
            localStorage.removeItem('mpv_logout_url')

            if (this.isDevelopment) {
              loginPath = 'http://dev.orcasnet.com:4000/#/login/' + workgroupCode
            }
            ReactNativeService.postMessage(JSON.stringify({ key: 'logout', value: null }))
            window.location.href = loginPath
          }
        }
      ),
      share()
    )
  }

  login(credentials: api.LoginPayload): Observable<Session> {
    return this.http
      .post<api.SessionData>('/api/v1/login.json', credentials, { observe: 'response' })
      .pipe(
        tap(({ body }) => {
          this.currentSessionSubject.next(new Session().deserialize(body))
        }),
        map(() => this.currentSessionSubject.value),
        share()
      )
  }

  getUserSettings(userName: string, workgroup_code: string, isMobileApp: boolean): Observable<any> {
    return this.http.post<any>(
      '/api/v1/otp/getusersettings' + (isMobileApp ? '?from=Mobile' : ''),
      {
        username: userName,
        workgroup_code: workgroup_code
      }
    )
  }

  // Destroy local session data.
  // Call this when it's known that the server doesn't have a valid session, otherwise call #logout.
  destroy(): void {
    this.isExpiringSoon = false
    this.currentSessionSubject.next(null)
    this.isAuthenticatedSubject.next(false)
  }

  // Destroy session on server. Also destroys local session data (via #destroy).
  // Call this when logging out.
  logout(): Observable<Object> {
    this.isExpiringSoon = false
    return this.http.post('/api/v1/logout.json', {}).pipe(
      tap(() => this.destroy()),
      share()
    )
  }

  // Renew the session expiration, throttle by `refreshSessionThrottleDuration`.
  // This gets called by AuthGuard on route change if user is authed.
  refreshSession(): void {
    if (!this.isInstantiated) {
      const subscription = this.refreshSessionSubject
        .pipe(
          throttleTime(this.refreshSessionThrottleDuration),
          tap(() => {
            this.isExpiringSoon = false
          }),
          switchMap(() => this.http.post('/api/v1/refresh_session.json', null))
        )
        .subscribe(() => this.populate().subscribe(), () => this.destroy())
      this.subs.push(subscription)
    }

    this.refreshSessionSubject.next()
  }

  ngOnDestroy() {
    // Make sure repopulateOnSessionExpiry subscription doesn't call `#populate` after destruction.
    this.currentSessionSubject.next(null)
    this.currentSessionSubject.complete()
    this.isAuthenticatedSubject.complete()
    this.subs.forEach(sub => sub.unsubscribe())
  }

  // Link isAuthenticatedSubject to currentSession$.
  private syncIsAuthenticatedWithCurrentSession() {
    const subscription = this.currentSession$
      .pipe(
        skip(1),
        map(user => user != null)
      )
      .subscribe(this.isAuthenticatedSubject)
    this.subs.push(subscription)
  }

  // Call #populate if time reaches `session_expires_at`. If server returns that a session isn't active, then the local
  // session data will be destroyed; otherwise, updated session data will be populated.
  private handleSessionExpiration(): void {
    const subscription = this.currentSession$
      .pipe(
        switchMap(session => {
          this.isExpiringSoon = false
          if (!session) return NEVER

          const now = new Date()
          const expireAt = new Date(session.session_expires_at)
          const warnAt = new Date(+expireAt - this.expiryWarning)
          const repopulateAt = new Date(+warnAt - 15000)

          // We'll repopulate the session 15 seconds before we need to warn the user that their session is going to
          // expire. This way, if the expiry date has changed and we're not _currently_ aware of it, we won't warn them
          // unnecessarily. If it's still the same, they'll get a warning at `warnAt`.
          if (now < repopulateAt) {
            return timer(repopulateAt).pipe(switchMap(() => this.populate()))
          }

          if (now < warnAt) {
            return timer(warnAt).pipe(
              tap(() => {
                this.isExpiringSoon = true
              }),
              delay(expireAt),
              switchMap(() => this.populate())
            )
          }

          this.isExpiringSoon = true

          return timer(expireAt).pipe(switchMap(() => this.populate()))
        })
      )
      .subscribe()
    this.subs.push(subscription)
  }

  getUserName(): string {
    return sessionStorage.getItem('currentUser')
  }
  setUserName(userName: string): void {
    sessionStorage.setItem('currentUser', userName)
  }

  getTokenMethod(): string {
    return this.token_method
  }
  setTokenMethod(tokenMethod: string): void {
    this.token_method = tokenMethod
  }

  updateProfileReminder(): Observable<api.SessionData> {
    let url = `/api/v1/update_profile_reminder`
    return this.http.post<any>(url, {}, { observe: 'response' }).pipe(
      tap(({ body }) => {
        this.currentSessionSubject.next(new Session().deserialize(body))
      }),
      map(() => this.currentSessionSubject.value),
      share()
    )
  }

  updateProfilePhoto(payload): Observable<any> {
    return this.http.put(`/api/v1/member_portrait.json`, payload)
  }
}
