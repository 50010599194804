export function downloadCSVFile(
  arrayTitles: Array<string>,
  arrayKeys: Array<string>,
  filename: string,
  rows: Array<any>
) {
  const rowsFile = []
  rowsFile.push(arrayTitles)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  rows.forEach(item => {
    const row = []
    arrayKeys.forEach(column =>
      row.push(
        typeof item[column] === 'number'
          ? item[column].toFixed(2)
          : item[column]
          ? `"${item[column]}"`
          : defaultValue(column)
      )
    )
    rowsFile.push(row)
  })

  let csvContent = ''
  rowsFile.forEach(row => {
    csvContent += row.join(',') + '\n'
  })

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
  const objUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', objUrl)
  link.setAttribute('download', `${filename}-${today.toLocaleDateString()}.csv`)
  link.textContent = 'Click to Download'
  link.click()
}

export function defaultValue(columnName: string) {
  var columnNames = 'cost_invested,cost_dividends,manual_basis,manual_dividends'
    .toLowerCase()
    .split(',')
  if (columnNames.indexOf(columnName.toLowerCase().trim()) >= 0) {
    return '0'
  }
  return ''
}
