import { Component, OnDestroy, OnInit } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { WorkgroupService } from '../../core/services'

const DOCUMENTS_DISCLAIMER_MESSAGE =
  'The ability to electronically retrieve or store your documents is provided for your convenience. These documents are not original legal documents or official records nor are they intended to replace your original legal documents or official records.'

@UntilDestroy()
@Component({
  selector: 'mpv-disclaimer',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  readonly copyrightYear = `1998\u2013${new Date().getFullYear()}`

  disclaimer: SafeHtml
  realmCode: string
  brokerDealerCode: string
  displayDisclaimer: boolean = false

  documentsDisclaimer: string

  constructor(private workgroup: WorkgroupService, router: Router) {
    router.events.pipe(untilDestroyed(this)).subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.documentsDisclaimer = router.url.includes('/documents')
          ? DOCUMENTS_DISCLAIMER_MESSAGE
          : ''
      }
    })
  }

  ngOnInit(): void {
    this.workgroup.currentWorkgroup$.pipe(untilDestroyed(this)).subscribe(workgroup => {
      this.disclaimer = workgroup.mpv_disclaimer
      this.brokerDealerCode = workgroup.broker_dealer_code
      this.realmCode = workgroup.realm_code
    })
  }

  ngOnDestroy(): void {}

  validateLinks(event) {
    if (!event) {
      return
    }
    event.preventDefault()
    let url = ''

    if (event.target.nodeName == 'A' || event.target.nodeName == 'a') {
      url = event.target.href
    } else if (
      event.target.parentElement.nodeName == 'A' ||
      event.target.parentElement.nodeName == 'a'
    ) {
      url = event.target.parentElement.href
    } else if (
      event.target.parentElement.parentElement.nodeName == 'A' ||
      event.target.parentElement.parentElement.nodeName == 'a'
    ) {
      url = event.target.parentElement.parentElement.href
    }

    if (url) {
      window.open(url, '_blank')
    }
  }

  defaultBrokerDealers() {
    const brokerDealerList = ['CG', 'NEXT', 'WIS']
    console.log(`Broker dealer ${this.brokerDealerCode}`)
    return !brokerDealerList.some(
      brokerdealer => brokerdealer.toUpperCase() === this.brokerDealerCode.toUpperCase()
    )
  }
}
