import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { startWith, tap } from 'rxjs/operators'

import { CacheService } from '../../core/services/cache.service'

@Injectable()
export class CacheInterceptorService implements HttpInterceptor {
  constructor (private cache: CacheService) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cacheMap = this.cache.getRequestCache(req)
    if (!cacheMap) return next.handle(req)

    const { urlWithParams } = req
    const cachedResponse = cacheMap.get(urlWithParams)
    const response$ = next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) cacheMap.set(urlWithParams, event)
      })
    )

    if (!cachedResponse) return response$

    return cacheMap.config.alwaysRefresh
      ? response$.pipe(startWith(new HttpResponse(cachedResponse)))
      : of(new HttpResponse(cachedResponse))
  }
}
